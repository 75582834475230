import React, { useEffect } from "react";
import styled from "styled-components";
import logoJDB from "../img/svg/logoJDB.svg";
import ok from "../img/svg/juego1/ok.svg";
import salir from "../img/svg/juego1/volver.svg";
import {
  BtnOk,
  DivCard,
  DivGralCard,
  DivImgLogo,
  Hr,
  ImagenOk,
} from "./modalMonto";

const ModalImporteunitario = ({
  importeUnitarioActual,
  setimporteUnitarioActual,
  viewModalimporte,
  setviewModalimporte,
  agregarApuesta,
}) => {
  return (
    viewModalimporte && (
      <DivGralCard>
        <DivCard className="card animate__animated animate__fadeInDownBig">
          <div className="card-header"></div>
          <div className="card-body">
            <div className="container">
              <DivImgLogo className="imgLogo" src={logoJDB} alt="logoJDB" />

              <div className="text-center">
                <h5>Digite el valor de la apuesta</h5>
              </div>
              <Hr />

              <div className="Row">
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      R$
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    value={importeUnitarioActual}
                    onChange={(e) => setimporteUnitarioActual(e.target.value)}
                  />
                </div>
              </div>
              <Hr />
            </div>
            {/* 
            <ButtonOk
              onClick={() => setviewModal(false)}
              className="btn btn-primary"
            >
              ok
            </ButtonOk> */}
          </div>
          <div className="row">
            <button
              className="col-4 offset-1"
              onClick={() => {
                setviewModalimporte(false);
              }}
              style={{
                height: "11vh",
                width: "11vh",
                border: "transparent",
                background: "transparent",
              }}
            >
              <img
                src={salir}
                alt="salir"
                style={{ height: "11vh", width: "11vh" }}
              />
            </button>
            <BtnOk
              className="col-4"
              onClick={() => {
                agregarApuesta();
              }}
            >
              <ImagenOk src={ok} alt="ok" />
            </BtnOk>
          </div>
        </DivCard>
      </DivGralCard>
    )
  );
};

export default ModalImporteunitario;
