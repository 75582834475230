import { createSlice } from "@reduxjs/toolkit";
import { fetchingIN, fetchingOUT } from "./fetchingSlice";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: undefined,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state, action) => {
      state.user = action.payload;
    },
    updateUser: (state, action) => {
      state.user.saldo = action.payload;
    },
  },
});

export const { login, logout, updateUser } = userSlice.actions;

export default userSlice.reducer;

//----------ACTIONS-------------------

export const handleLogin = (username, dni) => (dispatch) => {
  dispatch(fetchingIN());
  setTimeout(() => {
    let user = {
      username: username,
      dni: dni,
      userId: 1000,
      saldo: undefined,
    };
    dispatch(login(user));
    dispatch(fetchingOUT());
  }, 1000);
};

export const handleLogout = () => (dispatch) => {
  dispatch(fetchingIN());
  setTimeout(() => {
    let user = undefined;
    dispatch(logout(user));
    dispatch(fetchingOUT());
  }, 1000);
};

export const actualizarUsuario = (saldo) => (dispatch) => {
  dispatch(fetchingIN());
  setTimeout(() => {
    dispatch(updateUser(saldo));
    dispatch(fetchingOUT());
  }, 1000);
};
