import { createSlice } from "@reduxjs/toolkit";

export const fetchingSlice = createSlice({
  name: "fetching",
  initialState: {
    fetching: false,
  },
  reducers: {
    fetchingTrue: (state, action) => {
      state.fetching = action.payload;
    },
    fetchingFalse: (state, action) => {
      state.fetching = action.payload;
    },
  },
});

export const { fetchingTrue, fetchingFalse } = fetchingSlice.actions;

export default fetchingSlice.reducer;

//----------ACTIONS-------------------

export const fetchingIN = () => (dispatch) => {
  dispatch(fetchingTrue(true));
};

export const fetchingOUT = () => (dispatch) => {
  dispatch(fetchingFalse(false));
};
