import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import styled from "styled-components";
import fondo from "../img/svg/fondo_aterrizaje.svg";
import { ImagenFondo } from "./home";

const MinhaConta = (props) => {
  const [email, setemail] = useState("");
  const [nombre, setnombre] = useState("");
  const [apellido, setapellido] = useState("");
  const [documento, setdocumento] = useState("");
  const [cuil, setcuil] = useState("");
  const [direccion, setdireccion] = useState("");
  const [fechaNacimiento, setfechaNacimiento] = useState("");
  const [localidad, setlocalidad] = useState("");
  const [nroTelefono, setnroTelefono] = useState("");
  const [tipoDocumento, settipoDocumento] = useState("");
  const [provincia, setprovincia] = useState("");
  const [error, seterror] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DivDatos>
      <ImagenFondo src={fondo} className="App-logo" alt="logo" />
      <div className="animate__animated animate__fadeInRight">
        <Animated animationIn="slideInLeft" isVisible={true}>
          <div className="userPage" style={{ marginTop: "31%" }}>
            {error === false && (
              <div
                id="datosDesktop"
                className="tabletDatosPersonales"
                style={{ marginTop: "-3rem" }}
              >
                <div
                  id="datosPersonal"
                  className="col-10 colDatos tabletDatosPersonales"
                  style={{ marginRight: "3rem", marginLeft: "3rem" }}
                >
                  <DivH5
                    className="op-text bg-secondary-2 text-left p-2 pl-4 m-bold
                    mb-3"
                  >
                    <H5Title>Dados Pessoais</H5Title>
                  </DivH5>

                  <div
                    className="contDatosPersonales"
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                      backgroundColor: "#FFFFAC",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-10">
                        <SpanHeader className="op-text header-datos">
                          Nome e Sobrenome
                        </SpanHeader>
                        <br />
                        <SpanContent>Guillermo Martín</SpanContent>
                      </div>
                    </div>

                    <hr className="mt-2 mb-2" />

                    <div className="row">
                      <div className="col sm-4 ml-n3">
                        <SpanHeader className="op-text header-datos">
                          Nascimento
                        </SpanHeader>
                        <br />
                        <SpanContent>25/05/1985</SpanContent>
                      </div>

                      <div className="col sm-4 ml-n3">
                        <SpanHeader className="op-text header-datos">
                          RG
                        </SpanHeader>
                        <br />
                        <SpanContent>13030456</SpanContent>
                      </div>
                      <div className="col sm-6">
                        <SpanHeader className="op-text header-datos">
                          CPF{" "}
                        </SpanHeader>
                        <br />
                        <div className="ml-n1">
                          <SpanContent className="text-justify" id="headerSpan">
                            20-13030456-2
                          </SpanContent>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="datosContacto"
                  className="col-10 colDatos "
                  style={{ marginRight: "3rem", marginLeft: "3rem" }}
                >
                  <DivH5
                    style={{ marginTop: "10%" }}
                    className="op-text bg-secondary-2 text-left p-2 pl-4 m-bold mb-3"
                  >
                    <H5Title>Dados de Contato</H5Title>
                  </DivH5>

                  <div
                    className="contDatosContacto"
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                      backgroundColor: "#FFFFB9",
                    }}
                  >
                    <div className="row">
                      <div className="col sm-6">
                        <SpanHeader className="op-text header-datos">
                          Estado
                        </SpanHeader>
                        <br />
                        <SpanContent>Mato Grosso</SpanContent>
                      </div>
                      <div className="col sm-6">
                        <SpanHeader className="op-text header-datos">
                          Endereço
                        </SpanHeader>
                        <br />
                        <SpanContent>Aranda 90</SpanContent>
                      </div>
                    </div>

                    <hr className="mt-2 mb-2" />

                    <div className="row">
                      <div className="col sm-6">
                        <SpanHeader className="op-text header-datos">
                          Telefone
                        </SpanHeader>
                        <br />
                        <SpanContent>0303456</SpanContent>
                      </div>
                    </div>

                    <hr className="mt-2 mb-2" />

                    <div className="row">
                      <div className="col sm-6">
                        <SpanHeader className="op-text header-datos">
                          Email
                        </SpanHeader>
                        <br />
                        <SpanContent>usuario_123@mail.com</SpanContent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {error === true && (
              <div>
                <div style={{ textAlign: "center" }}>
                  <br></br>
                  <p>
                    La consulta de datos personales no se encuentra disponible.
                  </p>
                  <p>Favor reintente más tarde.</p>
                </div>
              </div>
            )}
          </div>
        </Animated>
      </div>
    </DivDatos>
  );
};

const DivDatos = styled.div``;

const DivH5 = styled.div`
  background-color: #00cc99;
`;

const SpanHeader = styled.span`
  font-size: larger;
`;

const SpanContent = styled.span`
  font-size: large;
`;

const H5Title = styled.h5`
  font-size: x-large;
`;

export default MinhaConta;
