import Login from "./screens/login";
import Home from "./screens/home";
import styled from "styled-components";
import Navbar from "./components/navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.css";
import Juego from "./screens/juegos/juego1/juego";
import Loading from "./screens/loading";
import CarregarCredito from "./screens/carregarCredito";
import PagarContas from "./screens/pagarContas";
import Sangria from "./screens/sangria";
import Premios from "./screens/premios";
import MinhaConta from "./screens/minhaConta";
import Totais from "./screens/totais";
import Evento from "./screens/juegos/juego1/eventos";
import axios from "axios";
import { useState } from "react";

function App() {
  const mkcert = require("mkcert");

  // create a certificate authority
  const ca = async () => {
    await mkcert.createCA({
      organization: "Hello CA",
      countryCode: "NP",
      state: "Bagmati",
      locality: "Kathmandu",
      validityDays: 365,
    });
  };

  // then create a tls certificate
  const cert = async () => {
    await mkcert.createCert({
      domains: ["127.0.0.1", "localhost"],
      validityDays: 365,
      caKey: ca.key,
      caCert: ca.cert,
    });
  };

  //console.log(cert.key, cert.cert); // certificate info
  //console.log(`${cert.cert}\n${ca.cert}`); // create a full chain certificate by merging CA and domain certificates

  const { user } = useSelector((state) => state.user);
  const { fetching } = useSelector((state) => state.fetching);
  const Rutas = () => (
    <Routes>
      <Route
        exact
        path="/carregarcredito"
        element={
          user !== undefined ? <CarregarCredito /> : <Navigate to="/login" />
        }
      />
      <Route
        exact
        path="/pagarcontas"
        element={
          user !== undefined ? <PagarContas /> : <Navigate to="/login" />
        }
      />
      <Route
        exact
        path="/sangria"
        element={user !== undefined ? <Sangria /> : <Navigate to="/login" />}
      />
      <Route
        exact
        path="/premios"
        element={user !== undefined ? <Premios /> : <Navigate to="/login" />}
      />
      <Route
        exact
        path="/minhaconta"
        element={user !== undefined ? <MinhaConta /> : <Navigate to="/login" />}
      />
      <Route
        exact
        path="/totais"
        element={user !== undefined ? <Totais /> : <Navigate to="/login" />}
      />
      <Route
        exact
        path="/"
        element={user !== undefined ? <Home /> : <Navigate to="/login" />}
      />
      <Route
        exact
        path="/login"
        element={user === undefined ? <Login /> : <Navigate to="/" />}
      />
      <Route
        exact
        path="/games/:gamename"
        element={user !== undefined ? <Juego /> : <Navigate to="/login" />}
      />
      <Route
        exact
        path="/games/:gamename/eventos"
        element={user !== undefined ? <Evento /> : <Navigate to="/login" />}
      />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );

  return fetching ? (
    <Loading />
  ) : (
    <AppStyle>
      {user === undefined ? <></> : <Navbar />}
      <ContenedorPlataforma>
        <Rutas />
      </ContenedorPlataforma>
    </AppStyle>
  );
}

export default App;

const AppStyle = styled.div``;

const ContenedorPlataforma = styled.div`
  position: fixed;
  width: 100%;
  height: 90%;
`;
