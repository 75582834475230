import React, { useState } from "react";
import styled from "styled-components";
import fondo from "../img/svg/fondo_aterrizaje.svg";
import { ImagenFondo } from "./home";
import logoJDB from "../img/svg/juego1/logoJDB.svg";
import ok from "../img/svg/juego1/ok.svg";
import logoRetiro from "../img/retiro-icon-color.png";
import inicio from "../img/svg/inicio.svg";
import { BiDownload } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { goTo } from "../redux/slices/location";
import { useNavigate } from "react-router-dom";

const Sangria = () => {
  const [viewCard, setviewCard] = useState(false);
  const [viewmodal, setviewmodal] = useState(false);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const CardCredenciaisComponent = () => {
    return (
      <DivContainerCard className="container animate__animated animate__fadeIn">
        <RowCardCredenciais className="row justify-content-center">
          <H2NomeUsuario>Nome do usuário</H2NomeUsuario>

          <DivCentrado className="col-12">
            <InputNomeUsuario type="text" />
          </DivCentrado>
          <H2NomeUsuario>Senha</H2NomeUsuario>

          <DivCentrado className="col-12">
            <InputNomeUsuario type="password" />
          </DivCentrado>

          <DivButton className="col-12">
            <ButtonOk
              onClick={() => {
                setviewmodal(true);
                setviewCard(false);
              }}
            >
              <img src={ok} alt="botonOk" />
            </ButtonOk>
          </DivButton>
        </RowCardCredenciais>
      </DivContainerCard>
    );
  };

  const ModalOperacionConfirmada = () => {
    return (
      <DivModal className="animate__animated animate__bounceInDown">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <DivContainerModal className="container">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <ImagenLogoModal src={logoJDB} alt="logoJDB Modal" />
                  </div>
                  <div className="col-12">
                    <H2Caixsa className="modal-title">
                      Operação confirmada
                    </H2Caixsa>
                  </div>
                </div>
              </DivContainerModal>

              <ButtonCloseModal
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setviewmodal(false)}
              >
                <span aria-hidden="true">X</span>
              </ButtonCloseModal>
            </div>
            <div className="modal-body">
              <DivContainerModalBody className="container">
                <RowiconoRetiro className="row justify-content-center">
                  <ColiconoRetiro className="col-12">
                    <ImagenLogoRetiro src={logoRetiro} alt="logoRetiro" />
                  </ColiconoRetiro>
                  <ColiconoRetiro className="col-12">
                    {" "}
                    <H2Caixsa>R$ 4000,00</H2Caixsa>
                  </ColiconoRetiro>
                </RowiconoRetiro>
              </DivContainerModalBody>
            </div>
            <div className="modal-footer">
              <DivContainerModalBody className="container">
                <RowiconoRetiro className="row justify-content-center">
                  <ColiconoRetiro className="col-12">
                    <ButtonHome
                      onClick={() => {
                        dispatch(goTo("/"));
                        navigate("/");
                      }}
                      type="button"
                      className="btn btn-primary"
                    >
                      <img src={inicio} alt="inicio" style={{ width: "2em" }} />
                    </ButtonHome>
                    <ButtonHome
                      type="button"
                      className="btn btn-warning"
                      data-dismiss="modal"
                    >
                      <Download>
                        <BiDownload />
                      </Download>
                    </ButtonHome>
                  </ColiconoRetiro>
                </RowiconoRetiro>
              </DivContainerModalBody>
            </div>
          </div>
        </div>
      </DivModal>
    );
  };

  return (
    <div>
      <ImagenFondo src={fondo} className="App-logo" alt="logo" />
      <div className="container-fluid animate__animated animate__fadeInRight">
        <DivRowSangria className="row">
          <DivCol3Tab className="col-3">
            <h5>TODOS</h5>
          </DivCol3Tab>
          <DivCol3Tab className="col-3">
            <h5>JDB</h5>
          </DivCol3Tab>
          <DivCol3Tab className="col-3">
            <h5>PROX..</h5>
          </DivCol3Tab>
          <DivCol3Tab className="col-3">
            <h5>PROX..</h5>
          </DivCol3Tab>
        </DivRowSangria>
        <DivRowContent className="row">
          <DivContainer className="container">
            <div className="row justify-content-center">
              <Col6Caixsa className="col-7">
                <H2Caixsa>R$ e CAIXSA </H2Caixsa>
              </Col6Caixsa>
              <div className="col-5">
                <LogoJDB src={logoJDB} alt="logoJDB" />{" "}
              </div>
              <DivCentrado className="col-12">
                <InputRetiro />
              </DivCentrado>

              <Col6Caixsa className="col-6">
                <H2Caixsa>R$ RETIRO </H2Caixsa>
              </Col6Caixsa>
              <div className="col-6"></div>

              <DivCentrado className="col-12">
                <InputRetiro value={"R$ 4000,00"} />
              </DivCentrado>
              <DivCentrado className="col-12">
                <BotonLogin
                  onClick={() => setviewCard(!viewCard)}
                  className="btn btn-secondary"
                  type="button"
                >
                  Credenciais
                </BotonLogin>
              </DivCentrado>
            </div>
          </DivContainer>
          {viewCard && <CardCredenciaisComponent />}
          {viewmodal && <ModalOperacionConfirmada />}
        </DivRowContent>
      </div>
    </div>
  );
};

export default Sangria;

const Download = styled.h1`
  font-size: 2em;
`;
const ButtonHome = styled.button`
  border-radius: 50%;
  width: 4em;
  height: 4em;
  box-shadow: 3px 3px 7px 7px #1c1a1a;
  margin: 2em 1em;
`;
const RowiconoRetiro = styled.div`
  margin-top: 3em;
`;
const ColiconoRetiro = styled.div``;

const ImagenLogoRetiro = styled.img``;

const DivContainerModal = styled.div`
  text-align: center;
  border-bottom-style: dashed;
  border-color: aquamarine;
`;
const DivContainerModalBody = styled.div`
  text-align: center;
`;

const ImagenLogoModal = styled.img`
  width: 9em;
`;

const DivContainer = styled.div`
  margin-top: 2em;
`;
const DivRowSangria = styled.div`
  margin-top: 14%;
`;

const DivCol3Tab = styled.div`
  text-align: center;
  border: solid;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  height: 3em;
  border-color: grey;
  border-bottom-style: none;
  padding-top: 1em;
`;

const DivRowContent = styled.div`
  height: 62vh;
`;

const LogoJDB = styled.img`
  width: 8em;
`;

const H2Caixsa = styled.h2`
  font-weight: bold;
  color: blue;
  font-size: 1em;
`;
const H2NomeUsuario = styled.h2`
  font-weight: bold;
  color: blue;
  text-align-last: start;
  margin-top: 0.5em;
  font-size: 1em;
`;

const Col6Caixsa = styled.div`
  align-self: end;
  text-align: end;
`;

const InputRetiro = styled.input`
  background-color: white;
  height: 3em;
  width: 75%;
  border: solid;
  border-radius: 1em;
  font-size: 1em;
  color: blue;
  border-color: black;
  font-weight: bold;
`;
const InputNomeUsuario = styled.input`
  width: 80%;
  height: 3em;
  border: solid;
  border-radius: 1em;
  font-size: 1em;
`;

const DivCentrado = styled.div`
  text-align-last: center;
`;
const BotonLogin = styled.button`
  width: 16rem;
  height: 4rem;
  font-size: 1rem;
  background: radial-gradient(
    circle at 90% 7%,
    #1a995b 1px,
    #1a995b 1%,
    #00cc99 57%,
    #223e37 100%
  );
  border-radius: 36px;
  margin-top: 1em;
`;

const RowCardCredenciais = styled.div`
  background-color: white;
  width: 75%;
  border: solid;
  border-radius: 2em;
  height: 15em;
`;

const DivContainerCard = styled.div`
  text-align: -webkit-center;
`;

const ButtonOk = styled.button`
  width: 3em;
  border-radius: 100%;
  background-color: #369f07;
  height: 3em;
  box-shadow: 6px 4px 9px 0px grey;
`;

const DivButton = styled.div`
  text-align: right;
  margin-right: 4rem;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const DivModal = styled.div`
  background: white;
  position: absolute;
  height: 61vh;
  width: 80%;
  left: 10%;
  border: solid;
  border-radius: 2em;
  box-shadow: 9px 11px 19px 4px #181717;
`;

const ButtonCloseModal = styled.button`
  border: none;
  color: black;
  background-color: transparent;
  font-weight: bold;
  font-size: 1em;
  position: absolute;
  right: 0;
  top: 0;
`;
