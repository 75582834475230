import React from "react";
import styled from "styled-components";
import fondo from "../img/svg/fondo_aterrizaje.svg";
import { ImagenFondo } from "./home";

const PagarContas = () => {
  return (
    <div>
      <ImagenFondo src={fondo} className="App-logo" alt="logo" />
      <div className="animate__animated animate__fadeInRight">
        <H1pagarContas className="text-center">
          Proximamente
          <h1 className="text-center text-uppercase font-weight-bold ">
            Pagar Contas
          </h1>
        </H1pagarContas>
      </div>
    </div>
  );
};

export default PagarContas;

const H1pagarContas = styled.h1`
  margin-top: 40vh;
  font-weight: bolder;
`;
