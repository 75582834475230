import React from "react";
import styled from "styled-components";

const ModalNotificaciones = ({ viewNotif, setviewNotif }) => {
  return (
    viewNotif && (
      <DivGralCard>
        <DivCard className="card text-center animate__animated animate__fadeInDownBig">
          <div className="card-header">Notificaciones</div>
          <div className="card-body">
            <h5 className="card-title">Special title treatment</h5>
            <p className="card-text">Próximamente...</p>
            <ButtonOk
              onClick={() => setviewNotif(false)}
              className="btn btn-primary"
            >
              ok
            </ButtonOk>
          </div>
        </DivCard>
      </DivGralCard>
    )
  );
};

export default ModalNotificaciones;

const DivCard = styled.div`
  position: fixed;
  top: 36%;
  left: 5%;
  min-width: 20em;
  min-height: 13em;
`;
const ButtonOk = styled.button`
  margin-top: 1em;
  min-width: 7em;
`;
const DivGralCard = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0000005e;
  position: fixed;
  top: 0;
  left: 0;
`;
