import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import logoJDB from "../img/svg/logoJDB.svg";
import fondo from "../img/svg/fondo_aterrizaje.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { goTo } from "../redux/slices/location";

const Home = () => {
  let dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  return (
    <>
      <ImagenFondo src={fondo} className="App-logo" alt="logo" />
      <DivHome className="container">
        <div className="row justify-content-center">
          <div className="col-6 offset-6">
            <BemVido>BEM-VINDO</BemVido>
          </div>
          <div className="col-6 offset-6">
            <Username>{user.username}</Username>
          </div>
          <DivLogoHome className="col-12">
            <img
              onClick={() => {
                navigate("/games/jogo-do-bicho");
                dispatch(goTo("/games/jogo-do-bicho"));
              }}
              src={logoJDB}
              alt="logoJDB"
            />
          </DivLogoHome>
          {/* <DivLogoHomeProximamente className="col-12">
            <SpanProximamente>PROXIMAMENTE...</SpanProximamente>
          </DivLogoHomeProximamente> */}
        </div>
      </DivHome>
    </>
  );
};

export default Home;

const DivHome = styled.div`
  margin-top: 10vh;
`;
export const ImagenFondo = styled.img`
  position: fixed;
  height: 109rem;
  top: -3rem;
  left: -45rem;
  z-index: -1;
`;
const BemVido = styled.h1`
  text-align: end;
  font-weight: bold;
  color: blue;
  font-size: 2em;
  white-space: nowrap;
  position: absolute;
  top: 3.8vh;

  right: 13.5vh;
  font-family: revert;
`;

const Username = styled.h1`
  text-align: end;
  font-weight: bold;
  color: blue;
  font-size: 2em;
  white-space: nowrap;
  margin-bottom: 2em;
  font-family: cursive;
  right: 14.5vh;
  position: fixed;
`;

const DivLogoHome = styled.div`
  border: solid;
  border-radius: 2em;
  box-shadow: 10px 10px 8px 0px grey;
  background: linear-gradient(45deg, #f7d92e, transparent);
  min-height: 13em;
  margin-top: 14vh;
  width: 75%;
`;
const DivLogoHomeProximamente = styled.div`
  border: solid;
  border-radius: 2em;
  box-shadow: 10px 10px 8px 0px grey;
  background: linear-gradient(45deg, #f7d92e, transparent);
  min-height: 13em;
  margin-top: 2vh;
  text-align: center;
  padding-top: 6em;
  width: 94%;
`;

const SpanProximamente = styled.span`
  font-size: 2em;
  font-weight: bolder;
`;
