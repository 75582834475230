import React from "react";
import { useNavigate } from "react-router-dom";
import {
  DivContainerElementos,
  DivSalir,
  LogoNombreElemento,
  SpanMenu,
} from "./navbar";
import resultados from "../img/svg/juego1/iconoresultados.svg";
import icono_reusar_ticket from "../img/svg/juego1/icono_reusar_ticket.svg";
import icono_ajudagrupo from "../img/svg/juego1/icono_ajudagrupo.svg";
import avede_icono from "../img/svg/juego1/avede_icono.svg";
import { handleLogout } from "../redux/slices/userSlice";
import salir from "../img/svg/salir.svg";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const NavJogodobicho = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
      className="offcanvas-body"
      style={{ marginLeft: "-2vh", marginTop: "4vh" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <DivContainerElementos className="container">
              <div className="row align-items-end">
                <DivIconoAve className="col-2">
                  <SpanMenu>
                    <img
                      src={avede_icono}
                      style={{ marginTop: "-0.5em" }}
                      alt="avede_icono"
                    />
                  </SpanMenu>
                </DivIconoAve>
                <div className="col-9">
                  <H5JogodoBicho>JOGO DO BICHO</H5JogodoBicho>
                </div>
              </div>
            </DivContainerElementos>
          </div>
          <div
            className="col-12"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <LogoNombreElemento
              icono={<ImagenLogo src={resultados} alt="resultados" />}
              nombre={"Resultados"}
            />
          </div>
          <div
            className="col-12"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <LogoNombreElemento
              icono={
                <ImagenLogo
                  src={icono_reusar_ticket}
                  alt="icono_reusar_ticket"
                />
              }
              nombre={"Reusar Ticket"}
            />
          </div>
          <div
            className="col-12"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <LogoNombreElemento
              icono={
                <ImagenLogo src={icono_ajudagrupo} alt="icono_ajudagrupo" />
              }
              nombre={"Ajuda Grupos"}
            />
          </div>
          <DivSalir
            onClick={() => {
              dispatch(handleLogout());
              navigate("/login");
            }}
            className="col-12"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <LogoNombreElemento
              icono={<ImagenLogo src={salir} alt="salir" />}
              nombre={"Sair"}
            />
          </DivSalir>
        </div>
      </div>
    </div>
  );
};

export default NavJogodobicho;

const ImagenLogo = styled.img`
  width: 2rem;
`;

const DivIconoAve = styled.div`
  width: 4em;
  border-radius: 100%;
  background-color: #9933cc;
  height: 4em;
  padding-top: 0.3em;
`;

const H5JogodoBicho = styled.div`
  color: #9933cc;
  font-size: 1em;
  font-family: cursive;
  margin-bottom: 0.5em;
  margin-left: 1em;
  white-space: nowrap;
  position: absolute;
  top: 22vh;
  left: 12vh;
`;
