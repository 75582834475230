import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MdMenu } from "react-icons/md";
import { BsPersonFill } from "react-icons/bs";
import NavPlataforma from "./navPlataforma";
import NavJogodobicho from "./navJogodobicho";
import volver from "../img/svg/juego1/volver.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import premio from "../img/svg/premios.svg";
import movil from "../img/svg/c_moviles.svg";
import notificaciones from "../img/svg/notificaciones.svg";
import p_servicios from "../img/svg/p_servicios.svg";
import mi_cuenta from "../img/svg/mi_cuenta.svg";
import inicio from "../img/svg/inicio.svg";
import salir from "../img/svg/salir.svg";
import totales from "../img/svg/totales.svg";
import sangria from "../img/svg/sangria.svg";
import { goTo } from "../redux/slices/location";
import $ from "jquery";
import ModalNotificaciones from "./modalNotificaciones";
import { handleLogout } from "../redux/slices/userSlice";
import TraerCuponesPendientes from "./cuponesPendientes";
import { Icon } from "@iconify/react";

export const LogoNombreElemento = ({ icono, nombre }) => {
  return (
    <DivContainerElementos className="container">
      <div className="row align-items-end">
        <div className="col-4">
          <SpanMenu>{icono}</SpanMenu>
        </div>
      </div>
    </DivContainerElementos>
  );
};
const Navbar = (props) => {
  const [navActual, setNavActual] = useState(<NavPlataforma />);
  const [viewNotif, setviewNotif] = useState(false);
  const [viewCup, setViewCup] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const { location } = useSelector((state) => state.location);
  let dispatch = useDispatch();

  const [visible, setvisible] = useState(false);

  useEffect(() => {
    switch (location) {
      case "/":
        setNavActual(
          <NavPlataforma viewNotif={viewNotif} setviewNotif={setviewNotif} />
        );
        break;
      default:
        setNavActual(<NavJogodobicho />);
        break;
    }
  }, [location]);

  return (
    <>
      <DivMenuRapido>
        <div
          className="row mx-auto p-1 text-center"
          style={{ backgroundColor: "#FFF", width: "100%" }}
        >
          <div
            onClick={() => {
              navigate("/");
              dispatch(goTo("/"));
            }}
            className="col-2"
          >
            <LogoNombreElemento
              icono={<ImagenLogo src={inicio} alt="inicio" />}
              nombre={"Inicio"}
            />
          </div>

          <div
            onClick={() => {
              navigate("/premios");
              dispatch(goTo("/"));
            }}
            className="col-2"
          >
            <LogoNombreElemento
              icono={<ImagenLogo src={premio} alt="premio" />}
              nombre={"Prêmios"}
            />
          </div>

          <div
            onClick={() => {
              navigate("/sangria");
              dispatch(goTo("/"));
            }}
            className="col-2"
          >
            <LogoNombreElemento
              icono={<ImagenLogo src={sangria} alt="sangria" />}
              nombre={"Sangria"}
            />
          </div>

          <div
            onClick={() => {
              navigate("/totais");
              dispatch(goTo("/"));
            }}
            className="col-2"
          >
            <LogoNombreElemento
              icono={<ImagenLogo src={totales} alt="totales" />}
              nombre={"Totais"}
            />
          </div>
          <div
            onClick={() => {
              navigate("/minhaconta");
            }}
            className="col-2"
          >
            <LogoNombreElemento
              icono={<ImagenLogo src={mi_cuenta} alt="mi cuenta" />}
              nombre={"Minha Conta"}
            />
          </div>
          <div
            onClick={() => {
              dispatch(handleLogout());
              navigate("/login");
            }}
            className="col-2"
          >
            <LogoNombreElemento
              icono={<ImagenLogo src={salir} alt="salir" />}
              nombre={"Sair"}
            />
          </div>
        </div>
        {location === "/" && (
          <div
            onClick={() => setViewCup(true)}
            data-toggle="modal"
            style={{
              cursor: "pointer",
              color: "#4a4a49",
              width: "fit-content",
            }}
            data-target="#cuponesModal"
            className="text-center text-small"
            id="pend"
          >
            <Icon
              style={{
                position: "fixed",
                top: "10px",
                right: "45px",
                color: "blue",
              }}
              rotate={1}
              icon="uil:ticket"
              width="30"
              height="30"
            />
          </div>
        )}
        {location === "/" && (
          <LogoNombreElemento
            icono={
              <ImagenLogo
                onClick={() => setviewNotif(true)}
                src={notificaciones}
                alt="notificaciones"
                style={{ position: "fixed", top: "11px", right: "15px" }}
              />
            }
            nombre={"Notificações"}
          />
        )}
        <ModalNotificaciones
          viewNotif={viewNotif}
          setviewNotif={setviewNotif}
        />

        <TraerCuponesPendientes viewCup={viewCup} setViewCup={setViewCup} />
      </DivMenuRapido>
      {location !== "/" && (
        <ImagenVolver
          onClick={() => {
            if (["/games/jogo-do-bicho/eventos"].includes(location)) {
              dispatch(goTo("/games/jogo-do-bicho"));
            } else if (["/games/jogo-do-bicho"].includes(location)) {
              dispatch(goTo("/"));
            }
            navigate(-1);
          }}
          src={volver}
          alt="volver"
        />
      )}
    </>
  );
};

//
export default Navbar;

const H5nombreNav = styled.h5`
  font-weight: bold;
  font-size: 1.5em;
  font-family: revert;
  white-space: nowrap;
  margin-bottom: 0.5em;
`;
const ImagenLogo = styled.img`
  width: 1.5rem;
`;

const BotonMenu = styled.button`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  position: fixed;
  top: 1vh;
  left: 1vh;
  background: radial-gradient(
    circle at 90% 7%,
    #73fb8a 1px,
    #cafde4 1%,
    #00cc99 57%,
    #223e37 100%
  );
  font-size: 2rem;
`;
const IconoUsuario = styled.div`
  font-size: 4rem;
  color: #00cc99;
`;
const NombreUsuario = styled.div`
  font-size: 2rem;
  color: #0066cc;
  padding-top: 2vh;
  font-weight: bold;
  font-family: revert;
`;
export const SpanMenu = styled.span`
  font-size: 2.5rem;
`;
export const DivContainerElementos = styled.div``;
export const DivSalir = styled.div`
  position: absolute;
  bottom: 1vh;
`;

const ImagenVolver = styled.img`
  position: fixed;
  left: 0em;
  width: 4em;
  z-index: 3;
  bottom: 8vh;
`;

const BotonVerMenu = styled.button`
  bottom: ${(props) => props.bottom};
  position: fixed;
  z-index: 1;
  background-color: rgb(227, 229, 231);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  left: 0;
`;

const DivMenuRapido = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  box-shadow: 0px 0 10px rgb(146 146 146 / 80%);
`;
