import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/userSlice";
import fetching from "./slices/fetchingSlice";
import location from "./slices/location";
import evento from "./slices/evento";
import parametros from "./slices/parametros";

//-------------STORES-----------------

export default configureStore({
  reducer: {
    user,
    fetching,
    location,
    evento,
    parametros,
  },
});
