import React from "react";
import styled from "styled-components";
import logoJDB from "../img/svg/logoJDB.svg";
import eliminar from "../img/svg/juego1/eliminar.svg";
import ok from "../img/svg/juego1/ok.svg";
import agregar from "../img/svg/juego1/agregar.svg";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";

const ModalTicketAposta = ({
  viewModal,
  setviewModal,
  cuponActual,
  viewBotonDescarga,
  downloadImg,
  ocultarOk,
}) => {
  const initialValue = 0;
  const { location } = useSelector((state) => state.location);
  return (
    viewModal && (
      <DivGralCard>
        <DivCard className="card animate__animated animate__fadeInDownBig">
          <div className="card-header"></div>
          <div className="card-body">
            <div className="container">
              <DivImgLogo className="imgLogo" src={logoJDB} alt="logoJDB" />

              <DivFechasTicket className="text-center">
                {cuponActual?.fechaApuesta?.substr(0, 10) || ""}
              </DivFechasTicket>
              <DivFechasTicket className="text-center">
                {cuponActual?.fechaEvento?.substr(0, 10) ||
                  "30/05/2022 - Maranhão 12:30Hs. | Federal 12:30Hs."}
              </DivFechasTicket>
              <div className="text-center"></div>
              <Hr />
              <div className="container">
                <div className="row">
                  <DivCol4Grey className="col-3">Tipo</DivCol4Grey>
                  <DivCol4Grey className="col-3">Nro.</DivCol4Grey>
                  <DivCol4Grey className="col-3">Unit.</DivCol4Grey>
                  <DivCol4Grey className="col-3">Tot.</DivCol4Grey>
                </div>
              </div>
              <Hr />
              {cuponActual?.apuestas.map((apuesta) => (
                <div className="container">
                  <div className="row">
                    <DivCol4Grey className="col-2">{apuesta.type}</DivCol4Grey>
                    <DivCol4GreyWrap className="col-4 text-center">
                      {apuesta.nros.join("-")}
                    </DivCol4GreyWrap>
                    <DivCol4Grey className="col-3">
                      ${apuesta.importeUnitario}
                    </DivCol4Grey>
                    <DivCol4Grey className="col-3">
                      ${apuesta.importeTotal}
                    </DivCol4Grey>
                  </div>
                </div>
              ))}

              <Hr />
              <div className="Row">
                <DivTotal className="col-18">
                  TOTAL: R$
                  {cuponActual?.apuestas
                    .map((apuesta) => apuesta.importeTotal)
                    .reduce(
                      (previousValue, currentValue) =>
                        previousValue + currentValue,
                      initialValue
                    )}
                </DivTotal>
              </div>
              <Hr />
              <div className="row">
                <DivTotal>
                  {cuponActual?.premios?.map((premio) => (
                    <div>Ganó R$ {premio.importePremio}</div>
                  ))}{" "}
                </DivTotal>
              </div>
            </div>
            {/* 
            <ButtonOk
              onClick={() => setviewModal(false)}
              className="btn btn-primary"
            >
              ok
            </ButtonOk> */}
          </div>
          <div className="row">
            {location !== "/" && !viewBotonDescarga && (
              <BtnAgregar
                className="col-4"
                src={agregar}
                alt="Agregar"
              ></BtnAgregar>
            )}
            {viewBotonDescarga && (
              <Icon
                onClick={downloadImg}
                style={{
                  position: "fixed",
                  color: " #e9ecef",
                  bottom: "0.6vh",
                  width: "5em",
                  height: "5em",
                  left: "3em",
                  borderRadius: "50%",
                  backgroundColor: "#00c68d",
                  boxShadow: "3px -4px 6px 2px grey",
                }}
                icon="typcn:download"
                width="30"
                height="30"
              />
            )}

            {location !== "/" && !viewBotonDescarga && (
              <BtnEliminar
                className="col-4"
                src={eliminar}
                alt="Eliminar"
              ></BtnEliminar>
            )}

            <BtnOk
              hidden={ocultarOk}
              className="col-4"
              onClick={() => setviewModal(false)}
            >
              <ImagenOk src={ok} alt="ok" />
            </BtnOk>
          </div>
        </DivCard>
      </DivGralCard>
    )
  );
};

export default ModalTicketAposta;

export const DivCard = styled.div`
  top: 10%;
  left: 5%;
  width: 20em;
  height: 33em;
`;
const ButtonOk = styled.button`
  margin-top: 1em;
  min-width: 7em;
`;
export const DivGralCard = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0000005e;
  position: fixed;
  top: 0;
  left: 0;
`;

export const DivImgLogo = styled.img`
  height: 4rem;
  margin-left: 5em;
`;

export const DivRow = styled.div`
  margin-top: -0.2em;
  margin-left: -0.5em;
`;

const ImgEliminar = styled.img`
  height: 3rem;
  border-radius: 50%;
  margin-left: 25em;
  margin-top: -3em;
`;
const BtnEliminar = styled.img`
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  margin-left: -2em;
  margin-top: -5.5em;
`;

const BtnAgregar = styled.img`
  height: 4.5rem;
  border-radius: 50%;
  margin-left: 4em;
  margin-top: -5.1em;
`;

const BtnOk = styled.button`
  height: 4.5rem;
  width: 4.5rem;
  background-color: #33cc33;
  border-radius: 50%;
  position: absolute;
  z-index: 3 !important;
  bottom: 1.5vh;
  right: 3vh;
  box-shadow: 1px 2px 5px 2px black;
`;

export const DivFechasTicket = styled.div`
  background-color: #;
  color: #0099cc;
  margin-left: 0.3em;
  font-size: 1rem;
  text-align: left;
`;

export const DivCol12Blue = styled.div`
  background-color: #;
  color: #0099cc;
  margin-left: 1em;
  margin-bottom: -0.8em;
  margin-top: 0.5em;
  font-size: 1rem;
  text-align: left;
`;

export const DivTotal = styled.div`
  background-color: #;
  color: #0099cc;
  margin-bottom: 1em;
  margin-top: 1em;
  font-size: 1rem;
  text-align: center;
`;

const DivCol6Blue = styled.div`
  background-color: #;
  color: #0099cc;
  margin-left: 13em;
  margin-top: -1.6em;
  margin-bottom: -1.5em;
  font-size: 1rem;
  text-align: left;
`;

export const DivCol4Grey = styled.div`
  color: grey;
  font-size: 1rem;
  text-align: left;
  white-space: nowrap;
`;
export const DivCol4GreyWrap = styled.div`
  color: grey;
  font-size: 1rem;
  text-align: left;
`;

const DivCol6Grey = styled.div`
  background-color: #;
  color: grey;
  margin-left: 13em;
  margin-top: -1.7em;
  margin-bottom: -1.5em;
  font-size: 1rem;
  text-align: left;
`;

export const Hr = styled.hr`
  border: 0;
  background-color: #fff;
  border-top: 3px dotted black;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

export const ImagenOk = styled.img`
  width: 5em;
  position: fixed;
  bottom: 0.1em;
  right: 1.1em;
`;

export const BotonDescarga = styled.button`
  margin-left: 2em;
  margin-top: 0px !important;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  background-color: yellow;
  color: black;
`;
export const SpanDescarga = styled.span`
  position: relative;
  left: -6px;
  transform: rotate(360deg);
  color: white;
`;
