import React from "react";
import styled from "styled-components";
import fondo from "../img/svg/fondo_aterrizaje.svg";
import { useEffect, useState } from "react";
import { ImagenFondo } from "./home";
import axios from "axios";
import { data } from "jquery";

const Totais = () => {
  const [totals, setTotals] = useState(undefined);
  const [premiosPagados, setPremiosPagados] = useState(undefined);
  const [retiros, setRetiros] = useState(undefined);
  const [totales, setTotales] = useState(undefined);
  const [vendido, setVendido] = useState(undefined);

  const initTotals = () => {
    axios.get(process.env.REACT_APP_TOTALES).then((response) => {
      // setTotals(response.data);
      setPremiosPagados(response.data.premiosPagados);
      setRetiros(response.data.retiros);
      setTotales(response.data.totales);
      setVendido(response.data.vendido);
    });
  };

  useEffect(() => {
    initTotals();
  }, []);

  return (
    <div>
      <ImagenFondo src={fondo} className="App-logo" alt="logo" />
      <div className="container animate__animated animate__fadeInRight">
        <DivRow className="row">
          <div className="col-5">
            <SpanTotal>TOTAL APURADO</SpanTotal>
            <br />
            <SpanFecha>21/06/2022 12:00 HRS.</SpanFecha>
          </div>
          <DivNombres className="col-12">
            ventas tickets vendidos hoje
          </DivNombres>
          <DivLabel className="col-11 text-success">R$ {vendido}</DivLabel>
          <DivNombres className="col-12">Prémios pagados</DivNombres>
          <DivLabel className="col-11 text-danger">
            R$ {premiosPagados}
          </DivLabel>
          <DivNombres className="col-12">Retiros</DivNombres>
          <DivLabel className="col-11 text-danger">R$ {retiros}</DivLabel>
          <DivNombres className="col-12">Totais</DivNombres>
          <DivLabel className="col-11">R$ {totales}</DivLabel>
        </DivRow>
      </div>
    </div>
  );
};

export default Totais;

const DivLabel = styled.div`
  background-color: white;
  height: 3em;
  border: solid;
  border-radius: 1em;
  font-size: 1.3em;
  color: blue;
  border-color: black;
  font-weight: bold;
  padding-top: 0.5em;
  padding-left: 2em;
`;

const DivNombres = styled.div`
  font-weight: bold;
  color: blue;
  font-size: 1.1em;
  white-space: nowrap;
  margin-top: 1em;
`;

const SpanTotal = styled.span`
  font-size: 2em;
  font-weight: bold;
  color: blue;
  white-space: nowrap;
`;

const SpanFecha = styled.span`
  font-size: 1em;
  font-weight: bold;
  color: blue;
  white-space: nowrap;
`;

const DivRow = styled.div`
  margin-top: 5em;
  margin-left: 1em;
`;
