import React from "react";
import styled from "styled-components";
import logoJDB from "../../../img/svg/logoJDB.svg";
import milhar from "../../../img/svg/juego1/M_negra.svg";
import centena from "../../../img/svg/juego1/C_negra.svg";
import g from "../../../img/svg/juego1/G_negra.svg";
import td from "../../../img/svg/juego1/td_negra.svg";
import inv from "../../../img/svg/juego1/inv.svg";
import rep from "../../../img/svg/juego1/rep.svg";
import DigitarNumero from "../utils/digitarNumero";
import { useEffect, useState } from "react";
import downloadjs from "downloadjs";
import DomToImage from "dom-to-image";
import ModalTicketAposta, {
  DivCard,
  DivCol12Blue,
  DivCol4Grey,
  DivFechasTicket,
  DivGralCard,
  DivImgLogo,
  DivRow,
  DivTotal,
  Hr,
} from "../../../components/modalTicketAposta";
import { useSelector } from "react-redux";
import axios from "axios";
import ModalMonto from "../../../components/modalMonto";
import ModalImporteunitario from "../../../components/modalimporteUnitario";
import ModalError from "../../../components/modalError";

const Eventos = () => {
  let negro =
    "invert(0%) sepia(0%) saturate(0%) hue-rotate(119deg) brightness(95%) contrast(102%)";
  let gris =
    "invert(74%) sepia(0%) saturate(1629%) hue-rotate(134deg) brightness(84%) contrast(87%)";
  let verde =
    "invert(57%) sepia(27%) saturate(2137%) hue-rotate(107deg) brightness(90%) contrast(99%)";

  const initialValue = 0;
  const [imagen, setimagen] = useState(undefined);
  const [imagenFondoBlanco, setimagenFondoBlanco] = useState(undefined);
  const [imagenFondoAmarillo, setimagenFondoAmarillo] = useState(undefined);
  const [viewModal, setviewModal] = useState(false);
  const [viewBotonM, setViewBotonM] = useState(negro);
  const [viewBotonC, setViewBotonC] = useState(negro);
  const [viewBotonG, setViewBotonG] = useState(negro);
  const [viewBotonInv, setViewBotonInv] = useState(negro);
  const [viewBotonRep, setViewBotonRep] = useState(gris);
  const [viewBotonTD, setViewBotonTD] = useState(negro);
  const [clickBotonM, setClickBotonM] = useState(false);
  const [clickBotonC, setClickBotonC] = useState(false);
  const [clickBotonInv, setClickBotonInv] = useState(false);
  const [clickBotonRep, setClickBotonRep] = useState(false);
  const [verDigitarNumero, setverDigitarNumero] = useState(false);
  const [apuestaActual, setApuestaActual] = useState("");
  const [listaNumero, setListaNumero] = useState([]);
  const [numeroArmado, setNumeroArmado] = useState("");
  const [listaApuesta, setListaApuesta] = useState([]);
  const [cuponGenerado, setcuponGenerado] = useState(undefined);
  const [valorTotal, setValorTotal] = useState(0);
  const [viewModalimporte, setviewModalimporte] = useState(false);
  const [importeUnitarioActual, setimporteUnitarioActual] = useState(undefined);
  const [viewModalError, setviewModalError] = useState(undefined);
  const [viewModalCupon, setviewModalCupon] = useState(false);
  const [modoTotal, setModoTotal] = useState(false);
  const [mensaje, setMensaje] = useState(undefined);
  const { evento } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);
  const { parametros } = useSelector((state) => state.parametros);
  const [hideDownload, sethideDownload] = useState(true);
  const [urlImagen, seturlImagen] = useState(undefined);

  const clickNumero = (id) => {
    setNumeroArmado(numeroArmado + id);
  };

  const generarLista = () => {
    let copiaLista = [...listaApuesta];

    let result = [];

    for (let i = 0; i < copiaLista.length; i++) {
      let apuesta = copiaLista[i].split(" ");

      let apuestaObj = {
        type: apuesta[0],

        numero: apuesta[1],
      };

      result.push(apuestaObj);
    }
    let resC = result.filter((elem) => elem.type === "C");

    let resCI = result.filter((elem) => elem.type === "CI");

    let resCIR = result.filter((elem) => elem.type === "CIR");

    let resM = result.filter((elem) => elem.type === "M");

    let resMI = result.filter((elem) => elem.type === "MI");

    let resMIR = result.filter((elem) => elem.type === "MIR");

    let resMC = result.filter((elem) => elem.type === "MC");

    let resMCI = result.filter((elem) => elem.type === "MCI");

    let resMCIR = result.filter((elem) => elem.type === "MCIR");

    let res1TD = result.filter((elem) => elem.type === "TD");

    let res1G = result.filter((elem) => elem.type === "G");

    let numerosC = resC.map((elem) => elem.numero);

    let numerosCI = resCI.map((elem) => elem.numero);

    let numerosCIR = resCIR.map((elem) => elem.numero);

    let numerosM = resM.map((elem) => elem.numero);

    let numerosMI = resMI.map((elem) => elem.numero);

    let numerosMIR = resMIR.map((elem) => elem.numero);

    let numerosMC = resMC.map((elem) => elem.numero);

    let numerosMCI = resMCI.map((elem) => elem.numero);

    let numerosMCIR = resMCIR.map((elem) => elem.numero);

    let numeros1TD = res1TD.map((elem) => elem.numero);

    let numeros1G = res1G.map((elem) => elem.numero);
  };

  const downloadImg = () => {
    let extCup = document.getElementById("download");

    sethideDownload(false);

    DomToImage.toPng(extCup).then(function (dataUrl) {
      seturlImagen(dataUrl);
      downloadjs(
        dataUrl,
        "Cupon Jogo do bicho " + cuponGenerado.fechaEvento + ".png"
      );
      sethideDownload(true);
      let pdfWindow = window.open("");
      pdfWindow.document.write(
        `<iframe width='fit-screen' height='100%' src='${
          dataUrl.split(",")[0]
        }, ${encodeURI(dataUrl.split(",")[1])}'></iframe>`
      );
      //window.open(dataUrl.split(",")[1]);
    });
  };

  const cuponDownload = (
    <DivGralCard
      hidden={hideDownload}
      id="download"
      style={{ backgroundColor: "white" }}
    >
      <DivCard>
        <div className="card-header"></div>
        <div className="card-body">
          <div className="container">
            <DivImgLogo className="imgLogo" src={logoJDB} alt="logoJDB" />

            <DivFechasTicket className="text-center">
              {cuponGenerado?.fechaApuesta?.substr(0, 10) || "30/05/2022"}
            </DivFechasTicket>
            <DivFechasTicket className="text-center">
              {cuponGenerado?.fechaEvento?.substr(0, 10) ||
                "30/05/2022 - Maranhão 12:30Hs. | Federal 12:30Hs."}
            </DivFechasTicket>
            <div className="text-center"></div>
            <Hr />
            <div className="container">
              <div className="row">
                <DivCol4Grey className="col-3">Tipo</DivCol4Grey>
                <DivCol4Grey className="col-4">Nro.</DivCol4Grey>
                <DivCol4Grey className="col-2">Unit.</DivCol4Grey>
                <DivCol4Grey className="col-3">Tot.</DivCol4Grey>
              </div>
            </div>
            <Hr />
            {cuponGenerado?.apuestas.map((apuesta) => (
              <div className="container">
                <div className="row">
                  <DivCol4Grey className="col-3">{apuesta.type}</DivCol4Grey>
                  <DivCol4Grey className="col-4">
                    {apuesta.nros.join("-")}
                  </DivCol4Grey>
                  <DivCol4Grey className="col-2">
                    ${apuesta.importeUnitario}
                  </DivCol4Grey>
                  <DivCol4Grey className="col-3">
                    ${apuesta.importeTotal}
                  </DivCol4Grey>
                </div>
              </div>
            ))}

            <Hr />
            <div className="Row">
              <DivTotal className="col-18">
                TOTAL: R$
                {cuponGenerado?.apuestas
                  .map((apuesta) => apuesta.importeTotal)
                  .reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue,
                    initialValue
                  )}
              </DivTotal>
            </div>
            <Hr />
            <div className="row">
              <DivTotal>
                {cuponGenerado?.premios?.map((premio) => (
                  <div>Ganó R$ {premio.importePremio}</div>
                ))}{" "}
              </DivTotal>
            </div>
          </div>
          {/* 
            <ButtonOk
              onClick={() => setviewModal(false)}
              className="btn btn-primary"
            >
              ok
            </ButtonOk> */}
        </div>
      </DivCard>
    </DivGralCard>
  );

  useEffect(() => {
    let nuevaLista = listaApuesta.map((apuesta) => ({
      ...apuesta,
      importeTotal: valorTotal / listaApuesta.length,
    }));
    setListaApuesta(nuevaLista);
  }, [valorTotal]);

  useEffect(() => {
    generarApuesta();
  }, [listaApuesta]);

  useEffect(() => {
    if (modoTotal) {
      setviewModal(true);
    }
  }, [modoTotal]);

  const clickOk = () => {
    setviewModalimporte(true);
  };

  const agregarApuesta = () => {
    let url = process.env.REACT_APP_VALIDAR_APUESTA;

    axios
      .post(url, {
        orden: listaApuesta.length + 1,
        tipo: apuestaActual.replace(" ", ""),
        nro:
          apuestaActual.includes("I") || apuestaActual.includes("I")
            ? numeroArmado.split("")
            : [numeroArmado],
        alcance: parametros.programas[0].alcanceMaximo,
        flagImporte: false,
        importe: importeUnitarioActual,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.status === "ok") {
          if (
            modoTotal &&
            data.importeTotal >
              valorTotal -
                listaApuesta
                  .map((apuesta) => apuesta.importeTotal)
                  .reduce(
                    (previousValue, currentValue) =>
                      previousValue + currentValue,
                    initialValue
                  )
          ) {
            setMensaje("Su jugada supera el monto que usted eligio apostar");
            setviewModalError(true);
          } else {
            setListaNumero([...listaNumero, numeroArmado]);
            let object = {
              orden: data.orden,
              type: data.tipo,
              nros: data.nro,
              alcance: data.alcance,
              importeUnitario: data.importeUnitario,
              importeTotal: data.importeTotal,
            };
            setListaApuesta([...listaApuesta, object]);
            setNumeroArmado("");
            setviewModalimporte(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setMensaje(err.response.data.errores[0].error);
        setviewModalError(true);
      });
  };

  const clickBorrar = () => {
    setListaNumero([]);
    setNumeroArmado("");
  };

  const generarApuesta = () => {
    let apuesta = {
      juego: "Jogo",
      descripcionEvento: "Joguitooo",
      userId: 1000,
      cantLineas: listaApuesta.length,
      importe: 12,
      modalidadImporte: false,
      agencia: "Local",
      maquina: "Messi",
      suerte: false,
      fechaEvento: "2022-08-05",
      apuestas: listaApuesta,
    };
    setcuponGenerado(apuesta);
  };

  const procesarApuesta = () => {
    generarApuesta();
    let url = process.env.REACT_APP_GRABAR_APUESTA;

    let body = {
      juego: "Jogo",
      descripcionEvento: "Joguitooo",
      userId: 1000,
      cantLineas: listaApuesta.length,
      importe: 12,
      modalidadImporte: false,
      agencia: "Local",
      maquina: "Messi",
      suerte: false,
      fechaEvento: "2022-08-05",
      apuestas: listaApuesta,
    };

    axios
      .post(url, body)
      .then((res) => {
        console.log(cuponGenerado);
        if (res.status === 200) {
          setviewModalCupon(true);
        }
      })
      .catch((error) => {
        setMensaje(
          "Su jugada no pudo ser procesada. Intente nuevamente más tarde."
        );
        setviewModalError(true);
      });
  };

  const handleClick = (id) => {
    let img = document.getElementById(id);

    if (img.style.filter === negro || img.style.filter === verde) {
      switch (id) {
        case "G":
          if (viewBotonM === negro) {
            setViewBotonM(gris);
          } else {
            setViewBotonM(negro);
          }
          if (viewBotonC === negro) {
            setViewBotonC(gris);
          } else {
            setViewBotonC(negro);
          }
          if (viewBotonInv === negro) {
            setViewBotonInv(gris);
          } else {
            setViewBotonInv(negro);
          }
          if (viewBotonRep === negro) {
            setViewBotonRep(gris);
          } else {
            setViewBotonRep(negro);
          }
          if (viewBotonTD === negro) {
            setViewBotonTD(gris);
          } else {
            setViewBotonTD(negro);
          }
          if (viewBotonG === verde) {
            setViewBotonG(negro);
          } else {
            setViewBotonG(verde);
          }
          break;
        case "TD":
          if (viewBotonM === negro) {
            setViewBotonM(gris);
          } else {
            setViewBotonM(negro);
          }
          if (viewBotonC === negro) {
            setViewBotonC(gris);
          } else {
            setViewBotonC(negro);
          }
          if (viewBotonInv === negro) {
            setViewBotonInv(gris);
          } else {
            setViewBotonInv(negro);
          }
          if (viewBotonRep === negro) {
            setViewBotonRep(gris);
          } else {
            setViewBotonRep(negro);
          }
          if (viewBotonG === negro) {
            setViewBotonG(gris);
          } else {
            setViewBotonG(negro);
          }
          if (viewBotonTD === verde) {
            setViewBotonTD(negro);
          } else {
            setViewBotonTD(verde);
          }
          break;
        case "M":
          if (
            clickBotonC === false &&
            clickBotonInv === false &&
            clickBotonRep === false
          ) {
            if (viewBotonG === negro) {
              setViewBotonG(gris);
            } else {
              setViewBotonG(negro);
            }
            if (viewBotonTD === negro) {
              setViewBotonTD(gris);
            } else {
              setViewBotonTD(negro);
            }
          }
          setClickBotonM((clickBotonM) => !clickBotonM);
          if (viewBotonM === verde) {
            setViewBotonM(negro);
          } else {
            setViewBotonM(verde);
          }
          break;
        case "C":
          if (
            clickBotonM === false &&
            clickBotonInv === false &&
            clickBotonRep === false
          ) {
            if (viewBotonG === negro) {
              setViewBotonG(gris);
            } else {
              setViewBotonG(negro);
            }
            if (viewBotonTD === negro) {
              setViewBotonTD(gris);
            } else {
              setViewBotonTD(negro);
            }
          }
          setClickBotonC((clickBotonC) => !clickBotonC);
          if (viewBotonC === verde) {
            setViewBotonC(negro);
          } else {
            setViewBotonC(verde);
          }
          break;
        case "R":
          if (
            clickBotonM === false &&
            clickBotonInv === false &&
            clickBotonC === false
          ) {
            if (viewBotonG === negro) {
              setViewBotonG(gris);
            } else {
              setViewBotonG(negro);
            }
            if (viewBotonTD === negro) {
              setViewBotonTD(gris);
            } else {
              setViewBotonTD(negro);
            }
          }
          setClickBotonRep((clickBotonRep) => !clickBotonRep);
          if (viewBotonRep === verde) {
            setViewBotonRep(negro);
          } else {
            setViewBotonRep(verde);
          }
          break;
        case "I":
          if (
            clickBotonM === false &&
            clickBotonRep === false &&
            clickBotonC === false
          ) {
            if (viewBotonG === negro) {
              setViewBotonG(gris);
            } else {
              setViewBotonG(negro);
            }
            if (viewBotonTD === negro) {
              setViewBotonTD(gris);
            } else {
              setViewBotonTD(negro);
            }
          }
          setClickBotonInv((clickBotonInv) => !clickBotonInv);
          if (viewBotonInv === verde) {
            setViewBotonInv(negro);
            setViewBotonRep(gris);
          } else {
            setViewBotonInv(verde);
            setViewBotonRep(negro);
          }
          break;
        default:
          break;
      }
    } else {
      switch (id) {
        case "G":
          setClickBotonInv(false);
          setClickBotonC(false);
          setClickBotonRep(false);
          setClickBotonM(false);
          setViewBotonG(verde);
          setViewBotonInv(gris);
          setViewBotonRep(gris);
          setViewBotonTD(gris);
          setViewBotonM(gris);
          setViewBotonC(gris);
          break;
        case "TD":
          setClickBotonInv(false);
          setClickBotonC(false);
          setClickBotonRep(false);
          setClickBotonM(false);
          setViewBotonG(gris);
          setViewBotonInv(gris);
          setViewBotonRep(gris);
          setViewBotonTD(verde);
          setViewBotonM(gris);
          setViewBotonC(gris);
          break;
        case "M":
          setClickBotonM(true);
          setViewBotonG(gris);
          setViewBotonInv(negro);
          setViewBotonRep(gris);
          setViewBotonTD(gris);
          setViewBotonM(verde);
          setViewBotonC(negro);
          break;
        case "C":
          setClickBotonC(true);
          setViewBotonG(gris);
          setViewBotonInv(negro);
          setViewBotonRep(gris);
          setViewBotonTD(gris);
          setViewBotonM(negro);
          setViewBotonC(verde);
          break;
        case "I":
          setClickBotonInv(true);
          setViewBotonG(gris);
          setViewBotonInv(verde);
          setViewBotonRep(negro);
          setViewBotonTD(gris);
          setViewBotonM(negro);
          setViewBotonC(negro);
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (viewBotonG === verde) {
      setApuestaActual("G");
    }
    if (viewBotonTD === verde) {
      setApuestaActual("TD");
    }
    if (
      viewBotonM === verde &&
      viewBotonC !== verde &&
      viewBotonRep !== verde &&
      viewBotonInv !== verde
    ) {
      setApuestaActual("M");
    }
    if (
      viewBotonM !== verde &&
      viewBotonC === verde &&
      viewBotonRep !== verde &&
      viewBotonInv !== verde
    ) {
      setApuestaActual("C");
    }
    if (
      viewBotonM !== verde &&
      viewBotonC !== verde &&
      viewBotonRep !== verde &&
      viewBotonInv === verde
    ) {
      setApuestaActual("I");
    }
    if (
      viewBotonM !== verde &&
      viewBotonC !== verde &&
      viewBotonRep === verde &&
      viewBotonInv !== verde
    ) {
      setApuestaActual("R");
    }
    if (
      viewBotonM === verde &&
      viewBotonC === verde &&
      viewBotonRep !== verde &&
      viewBotonInv !== verde
    ) {
      setApuestaActual("MC");
    }
    if (
      viewBotonM === verde &&
      viewBotonC !== verde &&
      viewBotonRep !== verde &&
      viewBotonInv === verde
    ) {
      setApuestaActual("MI");
    }
    if (
      viewBotonM !== verde &&
      viewBotonC === verde &&
      viewBotonRep !== verde &&
      viewBotonInv === verde
    ) {
      setApuestaActual("CI");
    }
    if (
      viewBotonM === verde &&
      viewBotonC === verde &&
      viewBotonRep !== verde &&
      viewBotonInv === verde
    ) {
      setApuestaActual("MCI");
    }
    if (
      viewBotonM === verde &&
      viewBotonC !== verde &&
      viewBotonRep === verde &&
      viewBotonInv === verde
    ) {
      setApuestaActual("MIR");
    }
    if (
      viewBotonM !== verde &&
      viewBotonC === verde &&
      viewBotonRep === verde &&
      viewBotonInv === verde
    ) {
      setApuestaActual("CIR");
    }
    if (
      viewBotonM === verde &&
      viewBotonC === verde &&
      viewBotonRep === verde &&
      viewBotonInv === verde
    ) {
      setApuestaActual("MCIR");
    }
    if (
      viewBotonM !== verde &&
      viewBotonC !== verde &&
      viewBotonRep !== verde &&
      viewBotonInv !== verde &&
      viewBotonG !== verde &&
      viewBotonTD !== verde
    ) {
      setApuestaActual("");
    }
  }, [
    viewBotonC,
    viewBotonG,
    viewBotonM,
    viewBotonTD,
    viewBotonInv,
    viewBotonRep,
  ]);

  useEffect(() => {
    console.log(listaApuesta);
  }, [listaApuesta]);

  useEffect(() => {
    import(`../../../img/svg/juego1/fondo_juego.svg`).then((image) =>
      setimagen(image.default)
    );
  }, []);
  useEffect(() => {
    import(`../../../img/svg/juego1/boton_blanco.svg`).then((image) =>
      setimagenFondoBlanco(image.default)
    );
    import(`../../../img/svg/juego1/boton_amarillo.svg`).then((image) =>
      setimagenFondoAmarillo(image.default)
    );
  }, []);

  return (
    <DivContainer>
      <ImagenFondo src={imagen} alt="fondo juego" />
      <div style={{ position: "fixed" }} className="col form-check form-switch">
        <SpanAposta>{modoTotal ? "T. Aposta" : "U. Aposta"}</SpanAposta>
        <Switch
          disabled={listaApuesta.length !== 0}
          checked={modoTotal}
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          onChange={() => setModoTotal(!modoTotal)}
        />
      </div>
      <div className="container">
        <div className="row">
          <FondoTotalJDB className="row">
            <div className="col-6">
              <p>Total</p>
              {modoTotal ? (
                <H5Precio>
                  R${" "}
                  {valorTotal
                    ? valorTotal -
                      listaApuesta
                        .map((apuesta) => apuesta.importeTotal)
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue + currentValue,
                          initialValue
                        )
                    : "0"}
                </H5Precio>
              ) : (
                <H5Precio>
                  R${" "}
                  {listaApuesta.length > 0
                    ? listaApuesta
                        .map((apuesta) => apuesta.importeTotal)
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue + currentValue,
                          initialValue
                        )
                    : "0"}
                </H5Precio>
              )}
            </div>
            <div className="col-6">
              <LogoJDBStyle src={logoJDB} alt="logoJDB" />
            </div>
          </FondoTotalJDB>
          <div className="col-12">
            <DivFechas>
              {evento.eventosList.map((eventoActual, index) => (
                <p key={index}>
                  {" "}
                  {eventoActual.listaSorteos[0].horario} HS -{" "}
                  {eventoActual.leyenda} |{" "}
                </p>
              ))}
            </DivFechas>
            <DivApostas>
              APOSTAS JÁ INSERIDAS
              <div
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderBottomLeftRadius: "6px",
                  borderBottomRightRadius: "6px",
                }}
              >
                <ul
                  size="3"
                  aria-label="size 3 select example"
                  style={{ overflowY: "scroll", maxHeight: "10vh" }}
                >
                  {listaApuesta.map((item, index) => (
                    <li key={index}>{item.type + " " + item.nros}</li>
                  ))}
                </ul>
              </div>
            </DivApostas>
            <DivApostaAtual>
              APOSTA ATUAL
              <FondoCard className="card">
                {viewBotonG === verde ? "Grupo" : ""}
                {viewBotonTD === verde ? "Terno de Dezena" : ""}
                {viewBotonM === verde ? "Milhar " : ""}
                {viewBotonM === verde &&
                viewBotonC !== verde &&
                viewBotonInv !== verde &&
                viewBotonRep !== verde
                  ? "Seca"
                  : ""}
                {viewBotonM === verde && viewBotonC === verde ? "Con " : ""}
                {viewBotonC === verde ? "Centena " : ""}
                {viewBotonC === verde &&
                viewBotonM !== verde &&
                viewBotonInv !== verde &&
                viewBotonRep !== verde
                  ? "Seca"
                  : ""}
                {viewBotonInv === verde ? "Invertida " : ""}
                {viewBotonRep === verde ? "Con Repetición" : ""}
                <br />
                {numeroArmado}
              </FondoCard>
            </DivApostaAtual>
          </div>
          {verDigitarNumero ? (
            <DigitarNumero
              clickNumero={clickNumero}
              clickOk={clickOk}
              clickBorrar={clickBorrar}
            />
          ) : (
            <>
              <div
                className="row"
                style={{
                  textAlignLast: "center",
                  marginTop: "-1.5em",
                  marginLeft: "1em",
                }}
              >
                <Col6Letras className="col-6">
                  <ImagenFondoBlanco
                    src={imagenFondoBlanco}
                    alt="imagenFondoBlanco"
                  />
                  <ImagenLetra
                    src={milhar}
                    alt="logoMilhar"
                    id="M"
                    onClick={(e) => handleClick(e.target.id)}
                    style={{ filter: viewBotonM }}
                  ></ImagenLetra>
                </Col6Letras>
                <Col6Letras className="col-6">
                  <ImagenFondoBlanco
                    src={imagenFondoBlanco}
                    alt="imagenFondoBlanco"
                  />
                  <ImagenLetra
                    src={centena}
                    alt="logoCentena"
                    id="C"
                    onClick={(e) => handleClick(e.target.id)}
                    style={{ filter: viewBotonC }}
                  ></ImagenLetra>
                </Col6Letras>
                <Col6Letras className="col-6">
                  <ImagenFondoBlanco
                    src={imagenFondoBlanco}
                    alt="imagenFondoBlanco"
                  />
                  <ImagenLetra
                    src={g}
                    alt="logoG"
                    id="G"
                    onClick={(e) => handleClick(e.target.id)}
                    style={{ filter: viewBotonG }}
                  ></ImagenLetra>
                </Col6Letras>
                <Col6Letras className="col-6">
                  <ImagenFondoBlanco
                    src={imagenFondoBlanco}
                    alt="imagenFondoBlanco"
                  />
                  <ImagenTD
                    src={td}
                    alt="logoTD"
                    id="TD"
                    onClick={(e) => handleClick(e.target.id)}
                    style={{ filter: viewBotonTD }}
                  ></ImagenTD>
                </Col6Letras>
              </div>
              <RowbotonesAmarillos className="row">
                <div className="col-6">
                  <ImagenFondoBlanco
                    src={imagenFondoAmarillo}
                    alt="imagenFondoBlanco"
                  />
                  <ImagenLetra
                    src={inv}
                    alt="logoMilhar"
                    id="I"
                    onClick={(e) => handleClick(e.target.id)}
                    style={{
                      width: "2.6em",
                      marginLeft: "1.1em",
                      marginTop: "2.2em",
                      filter: viewBotonInv,
                    }}
                  ></ImagenLetra>
                </div>
                <div className="col-6" style={{ marginLeft: "-4em" }}>
                  <ImagenFondoBlanco
                    src={imagenFondoAmarillo}
                    alt="imagenFondoBlanco"
                  />
                  <ImagenLetra
                    src={rep}
                    alt="logoMilhar"
                    id="R"
                    onClick={(e) => handleClick(e.target.id)}
                    style={{
                      width: "3em",
                      marginLeft: "1.1em",
                      marginTop: "2.2em",
                      filter: viewBotonRep,
                    }}
                  ></ImagenLetra>
                </div>
              </RowbotonesAmarillos>
            </>
          )}
        </div>

        <BotonAcordion
          onClick={() => {
            if (apuestaActual.length === 0) {
              setMensaje("Seleccione un tipo de apuesta");
              setviewModalError(true);
            } else {
              setverDigitarNumero(!verDigitarNumero);
            }
          }}
        >
          {!verDigitarNumero ? "Digitar Números" : "Aposta"}
        </BotonAcordion>
        {listaApuesta.length !== 0 && (
          <BotonFinalizarTicket onClick={() => procesarApuesta()}>
            FINALIZAR TICKET
          </BotonFinalizarTicket>
        )}
        {cuponGenerado && cuponDownload}
        {modoTotal && (
          <ModalMonto
            setviewModalMonto={setviewModal}
            viewModalMonto={viewModal}
            cuponGenerado={cuponGenerado}
            setValorTotal={setValorTotal}
            valorTotal={valorTotal}
          />
        )}

        <ModalImporteunitario
          importeUnitarioActual={importeUnitarioActual}
          setimporteUnitarioActual={setimporteUnitarioActual}
          viewModalimporte={viewModalimporte}
          setviewModalimporte={setviewModalimporte}
          agregarApuesta={agregarApuesta}
        />

        <ModalError
          mensaje={mensaje}
          viewModalError={viewModalError}
          setviewModalError={setviewModalError}
        />
        <ModalTicketAposta
          viewModal={viewModalCupon}
          setviewModal={setviewModalCupon}
          cuponActual={cuponGenerado}
          viewBotonDescarga={true}
          downloadImg={downloadImg}
        />
      </div>
    </DivContainer>
  );
};

export default Eventos;

const SelectApostas = styled.select`
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  max-height: 5rem;
`;

const H5Precio = styled.h5`
  margin-top: -3vh;
  margin-left: 4vh;
  white-space: nowrap;
`;

const LogoJDBStyle = styled.img`
  position: fixed;
  width: 9em;
  top: 2vh;
  right: 1vh;
`;

export const DivSpanTextVerde = styled.span`
  text-align-last: end;
  font-weight: bolder;
  color: #2f4c20;
`;

const Col6Letras = styled.div`
  margin-top: 0.9em;
  margin-right: -3em;
`;

const RowbotonesAmarillos = styled.div`
  margin-top: 1.5em;
  margin-left: 0.5em;
`;

const DivContainer = styled.div`
  background-image: url(${(props) => props.fondo});
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
`;

const ImagenFondo = styled.img`
  position: fixed;
  z-index: -1;
  width: 177em;
  left: -79em;
  top: -18em;
  height: 147vh;
`;

const FondoTotalJDB = styled.div`
  border-radius: 1em;
  background-color: white;
  border-style: solid;
  width: 18rem;
  height: 8vh;
  margin-top: 2em;
  margin-left: 4em;
  font-weight: bolder;
`;

const DivFechas = styled.div`
  background-color: white;
  box-shadow: -1px 4px 9px #0099cc;
  border-color: #0099cc;
  border-radius: 1em;
  margin-bottom: 0.2vh;
  border-style: groove;
  padding: 7px;
  font-weight: bolder;
  font-size: 1.8vh;
  color: #4c9acc;
  margin-top: 1vh;
  display: flex;
`;

const DivApostas = styled.div`
  background-color: #0099cc;
  border-radius: 1em;
  margin-bottom: 1vh;
  margin-top: 1vh;
  text-align: center;
  color: white;
  border-style: hidden;
`;

const DivApostaAtual = styled.div`
  background-color: #000066;
  color: white;
  text-align: center;
  border-radius: 1em;
`;

const FondoCard = styled.div`
  background-color: #ffffcc;
  color: #000066;
  font-weight: bolder;
  text-align: justify;
  padding-left: 1em;
  height: 3em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  font-size: 0.9em;
`;

const ImagenLetra = styled.img`
  width: 2em;
  margin-top: 2.5em;
  margin-left: 1.7em;
`;

const ImagenTD = styled.img`
  width: 2.5em;
  margin-left: 1.6em;
  margin-top: 3em;
`;

const ImagenFondoBlanco = styled.img`
  position: absolute;
  width: 5em;
  z-index: -1;
`;

const BotonFinalizarTicket = styled.button`
  position: fixed;
  bottom: 70px;
  right: 0em;
  background-color: #5aba8d;
  color: white;
  font-size: 1em;
  width: 9.7em;
  height: 3em;
  border-radius: 1em;
  box-shadow: 5px 6px 7px #0e0e0e;
  font-weight: bolder;
`;

const BotonAcordion = styled.button`
  background-color: #000066 !important;
  color: #ffffff !important;
  height: 3em;
  border-radius: 0.5em;
  width: 8.5rem !important;
  font-weight: bold;
  position: fixed;
  left: 4em;
  bottom: 70px;
  text-align: center;
  white-space: nowrap;
`;
const Switch = styled.input`
  height: 2em;
  width: 3em !important;
  position: fixed;
  top: 6vh;
  left: 7vh;
  :checked {
    background-color: #00cc99;
    border-color: #00cc99;
  }
`;
const SpanAposta = styled.span`
  position: fixed;
  font-weight: bolder;
  top: 30px;
  left: 8px;
  font-size: 0.7em;
`;
