import { createSlice } from "@reduxjs/toolkit";
import { fetchingIN, fetchingOUT } from "./fetchingSlice";

export const parametrosSlice = createSlice({
  name: "parametros",
  initialState: {
    parametros: undefined,
  },
  reducers: {
    setParametros: (state, action) => {
      state.parametros = action.payload;
    },
  },
});

export const { setParametros } = parametrosSlice.actions;

export default parametrosSlice.reducer;

//----------ACTIONS-------------------

export const handleinitParametros = (parametros) => (dispatch) => {
  dispatch(fetchingIN());
  setTimeout(() => {
    dispatch(setParametros(parametros));
    dispatch(fetchingOUT());
  }, 1000);
};
