import React, { useEffect, useState } from "react";
import styled from "styled-components";
import fondoloading from "../img/fondocon_logo.png";
import { useSelector } from "react-redux";

const Loading = () => {
  const [imagen, setimagen] = useState(undefined);

  useEffect(() => {
    import(`../img/fondocon_logo.png`).then((image) =>
      setimagen(image.default)
    );
  }, []);

  const { fetching } = useSelector((state) => state.fetching);
  return (
    <DivLoading fondoloading={imagen}>
      <DivSpinner className="d-flex justify-content-center">
        <DivSpinnerBorder className="spinner-grow text-dark" role="status">
          <span className="sr-only"></span>
        </DivSpinnerBorder>
        <DivSpinnerBorder className="spinner-grow text-dark" role="status">
          <span className="sr-only"></span>
        </DivSpinnerBorder>
        <DivSpinnerBorder className="spinner-grow text-dark" role="status">
          <span className="sr-only"></span>
        </DivSpinnerBorder>
        <DivSpinnerBorder className="spinner-grow text-dark" role="status">
          <span className="sr-only"></span>
        </DivSpinnerBorder>
      </DivSpinner>
    </DivLoading>
  );
};

export default Loading;

const DivLoading = styled.div`
  background-image: url(${(props) => props.fondoloading});
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
`;

const DivSpinner = styled.div`
  position: fixed;
  left: 28%;
  top: 54%;
  font-size: 1em;
`;
const DivSpinnerBorder = styled.div`
  margin-left: 0.5em;
`;
