export const parametros = {
  programas: [
    {
      descripcionJuego: "Jogo Do Bicho",
      alcanceMaximo: 10,
      maximaCantidadLineas: 6,
      importeMinimoCupon: 0.5,
      importeMaximoCupon: 1000,
      parametrosTipoApuesta: [
        {
          tipoApuesta: "C",
          parametrosGenerales: {
            maxCantidadCifras: 3,
            minCantidadCifras: 3,
            separador: ".",
            alcance: 5,
            puedeRepetir: true,
          },
        },
        {
          tipoApuesta: "CI",
          parametrosGenerales: {
            maxCantidadCifras: 10,
            minCantidadCifras: 3,
            separador: "·",
            alcance: 5,
            puedeRepetir: true,
          },
        },
        {
          tipoApuesta: "CIR",
          parametrosGenerales: {
            maxCantidadCifras: 10,
            minCantidadCifras: 3,
            separador: ".",
            alcance: 5,
            puedeRepetir: false,
          },
        },
        {
          tipoApuesta: "M",
          parametrosGenerales: {
            maxCantidadCifras: 4,
            minCantidadCifras: 4,
            separador: ".",
            alcance: 5,
            puedeRepetir: true,
          },
        },
        {
          tipoApuesta: "MI",
          parametrosGenerales: {
            maxCantidadCifras: 10,
            minCantidadCifras: 4,
            separador: "·",
            alcance: 5,
            puedeRepetir: true,
          },
        },
        {
          tipoApuesta: "MIR",
          parametrosGenerales: {
            maxCantidadCifras: 4,
            minCantidadCifras: 4,
            separador: "·",
            puedeRepetir: false,
          },
        },
        {
          tipoApuesta: "MC",
          parametrosGenerales: {
            maxCantidadCifras: 4,
            minCantidadCifras: 4,
            separador: ".",
            alcance: 5,
            puedeRepetir: true,
          },
        },
        {
          tipoApuesta: "MCI",
          parametrosGenerales: {
            maxCantidadCifras: 10,
            minCantidadCifras: 4,
            separador: "·",
            alcance: 5,
            puedeRepetir: true,
          },
        },
        {
          tipoApuesta: "MCIR",
          parametrosGenerales: {
            maxCantidadCifras: 10,
            minCantidadCifras: 4,
            separador: "·",
            alcance: 5,
            puedeRepetir: false,
          },
        },
        {
          tipoApuesta: "TD",
          parametrosGenerales: {
            maxCantidadCifras: 2,
            minCantidadCifras: 2,
            separador: "x",
            conector: " ",
            puedeRepetir: true,
          },
        },
        {
          tipoApuesta: "G",
          parametrosGenerales: {
            maxCantidadCifras: 2,
            minCantidadCifras: 2,
            separador: " ",
            puedeRepetir: true,
          },
        },
        {
          tipoApuesta: "CAO",
          parametrosGenerales: {
            maxCantidadCifras: 2,
            minCantidadCifras: 2,
            separador: ".",
            puedeRepetir: true,
          },
        },
        {
          tipoApuesta: "MAO",
          parametrosGenerales: {
            maxCantidadCifras: 2,
            minCantidadCifras: 2,
            separador: ".",
            puedeRepetir: true,
          },
        },
      ],
    },
  ],
  eventosDisponibles: [
    {
      orden: 0,
      descEve: "FEDERAL",
      logo: "logoEvento",
      idEvento: 1,
      tipoSorteo: "JDB",
      listaEventos: [
        {
          id: 0,
          leyenda: "Fortaleza",
          logo: "logo.png",
          listaSorteos: [
            {
              id: 0,
              horario: "11:00",
            },
            {
              id: 1,
              horario: "11:00",
            },
          ],
        },
        {
          id: 1,
          leyenda: "Fortaleza",
          logo: "logo.png",
          listaSorteos: [
            {
              id: 2,
              horario: "14:00",
            },
            {
              id: 3,
              horario: "14:00",
            },
          ],
        },
      ],
    },
  ],
};
