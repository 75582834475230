import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { handleLogin } from "../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import {
  BsFillKeyFill,
  BsPersonFill,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import "animate.css";
import logo_loteria from "../img/svg/logo_loteria.svg";
import fondoLogin from "../img/svg/fondologin.svg";

const Login = () => {
  const [verlogin, setverlogin] = useState(true);
  const [viewPass, setviewPass] = useState(false);
  const [username, setUsername] = useState(localStorage.getItem("user") || "");
  const [password, setpassword] = useState(
    localStorage.getItem("password") || ""
  );
  // eslint-disable-next-line no-unused-vars
  const [animateView, setanimateView] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const guardarDatos = (cheked) => {
    if (cheked) {
      localStorage.setItem("user", username);
      localStorage.setItem("password", password);
    } else {
      localStorage.clear();
    }
  };

  const Modalerror = () => {
    return (
      <>
        <button
          hidden={true}
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          id="modalErrorLogin"
        ></button>

        <div
          className="modal fade"
          id="exampleModal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          tabIndex={-1}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Erro de login</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <h5>Seu nome de usuário e/ou senha são inválidos</h5>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Sair
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const doLogin = () => {
    if (username === "tecnoaccion" && password === "abc123") {
      dispatch(handleLogin(username, 33333333));
      navigate("/");
    } else {
      document.getElementById("modalErrorLogin").click();
      let elements = document.getElementsByClassName(
        "modal-backdrop fade show"
      );

      let element = elements[0];
      element.remove();
    }
  };
  return (
    <>
      <ImagenLogin src={fondoLogin} alt="logo" />
      <Modalerror />
      <DivLogin className="animate__animated animate__fadeInDownBig container">
        <img src={logo_loteria} alt="logo_loteria" />
      </DivLogin>
      <DivLogin className="animate__animated animate__fadeInUpBig container">
        <RowBoton className="row justify-content-center">
          <div className="col-12">
            <BotonLogin className="btn btn-secondary" type="button">
              Conecte-se
            </BotonLogin>
          </div>
        </RowBoton>
        <div className="row">
          <div className="col-12">
            <div
              className={
                verlogin
                  ? "animate__animated  animate__fadeIn"
                  : "animate__animated  animate__fadeOut"
              }
            >
              <DivTarjeta className="card text-center">
                <div className="card-header"></div>
                <div className="card-body">
                  <Divinput className="input-group flex-nowrap">
                    <span className="input-group-text" id="addon-wrapping">
                      <BsPersonFill />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      aria-label="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Divinput>

                  <Divinput>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsFillKeyFill />
                      </span>
                      <input
                        type={viewPass ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="addon-wrapping"
                        value={password}
                        onChange={(e) => setpassword(e.target.value)}
                      />
                      <span className="input-group-text">
                        {viewPass ? (
                          <BsFillEyeFill onClick={() => setviewPass(false)} />
                        ) : (
                          <BsFillEyeSlashFill
                            onClick={() => setviewPass(true)}
                          />
                        )}
                      </span>
                    </div>
                  </Divinput>
                  <DivLembrar>
                    <div className="form-check form-switch">
                      <input
                        className="bg-warning form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onClick={(e) => guardarDatos(e.target.checked)}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Lembrar
                      </label>
                    </div>
                  </DivLembrar>

                  <BotonEntrar
                    disabled={!username || !password}
                    className="btn btn-primary"
                    onClick={() => {
                      doLogin();
                    }}
                  >
                    ENTRAR
                  </BotonEntrar>
                </div>
              </DivTarjeta>
            </div>
          </div>
        </div>
      </DivLogin>
    </>
  );
};

export default Login;

const RowBoton = styled.div`
  text-align-last: center;
`;

const DivLogin = styled.div`
  margin-top: 10vh;
`;

const BotonLogin = styled.button`
  width: 16rem;
  height: 4rem;
  font-size: 1.5rem;
  background: radial-gradient(
    circle at 90% 7%,
    #1a995b 1px,
    #1a995b 1%,
    #00cc99 57%,
    #223e37 100%
  );
  position: fixed;
  top: -7vh;
  left: 16vw;
  z-index: 23;
`;

const InputLogin = styled.input`
  width: 70%;
  height: 3rem;
  margin-top: 2vh;
  margin-left: 3vh;
`;

const DivTarjeta = styled.div`
  border-radius: 2em;

  background-color: #ffffcc;
`;
const DivBotonEntrar = styled.div`
  text-align: center;
`;
const BotonEntrar = styled.button`
  margin-top: 5vh;
  width: 8rem;
  height: 2.5rem;
  font-size: 1rem;
`;

const CheckedInput = styled.form`
  background-color: #f1b616;
  border-color: #f5d301;
`;

const Divinput = styled.div`
  margin-top: 2vh;
  padding-left: 3vh;
  padding-right: 3vh;
`;

const H5OlvidoContraseña = styled.h5`
  margin-top: 2vh;
`;
const ImagenLogin = styled.img`
  position: fixed;
  height: 179rem;
  top: -32rem;
  left: -92rem;
  z-index: -1;
`;

const DivLembrar = styled.div`
  text-align-last: start;
  margin-left: 3em;
  font-size: 1.5em;
  font-weight: bolder;
  color: grey;
  margin-top: 1em;
`;
