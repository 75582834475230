import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import fondo from "../img/svg/fondo_aterrizaje.svg";
import premios_ganador from "../img/svg/premios/premios_ganador.svg";
import premios_no_ganador from "../img/svg/premios/premios_no_ganador.svg";
import { ImagenFondo } from "./home";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchingIN, fetchingOUT } from "../redux/slices/fetchingSlice";
import ModalTicketAposta from "../components/modalTicketAposta";

const Premios = () => {
  let dispatch = useDispatch();
  const [imagenPremioGanador, setimagenPremioGanador] = useState(true);
  const [numeroCupon, setnumeroCupon] = useState(undefined);
  const [cuponActual, setCuponActual] = useState(undefined);
  const [viewModal, setviewModal] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_VER_LISTADO_CUPONES + "/1000")
      .then((res) => res);
  }, []);

  useEffect(() => {
    console.log(cuponActual);
  }, [cuponActual]);

  const handlebuscar = (e) => {
    axios
      .get(process.env.REACT_APP_VER_CUPON + `/${numeroCupon}`)
      .then((res) => res.data)
      .then((data) => {
        setCuponActual(data);
        setviewModal(true);
      });
  };

  return (
    <div>
      <ImagenFondo src={fondo} className="App-logo" alt="logo" />
      <div className="container">
        <div className="row">
          <DivBuscarCupon className="input-group mb-3">
            <input
              type="number"
              className="form-control"
              placeholder="Nro. cupon"
              aria-label="Nro. cupon"
              aria-describedby="basic-addon2"
              value={numeroCupon}
              onChange={(e) => setnumeroCupon(e.target.value)}
            />
            <div className="input-group-append">
              <button
                disabled={numeroCupon === undefined}
                className="btn btn-primary"
                type="button"
                onClick={(e) => handlebuscar(e)}
              >
                Buscar
              </button>
            </div>
          </DivBuscarCupon>
        </div>
      </div>

      <div className="row">
        {cuponActual?.premios.length > 0 ? (
          <div>
            {cuponActual?.premios.map((premio) => (
              <diV>
                <ModalTicketAposta
                  viewModal={viewModal}
                  setviewModal={setviewModal}
                  cuponActual={cuponActual}
                />
              </diV>
            ))}
          </div>
        ) : (
          <div>
            <p> Su cupon no tiene un premio asignado o no existe</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Premios;

const DivBuscarCupon = styled.div`
  position: fixed;
  top: 22%;
  width: 80%;
  left: 10%;
`;

const DivRowDatosCupon = styled.div`
  margin-top: 64%;
  font-family: bold;
  width: 100%;
  text-align: -webkit-center;
  font-size: x-large;
  font-weight: bolder;
  margin-left: 2%;
`;
