import React from "react";
import styled from "styled-components";
import fondo from "../img/svg/fondo_aterrizaje.svg";
import { ImagenFondo } from "./home";

const CarregarCredito = () => {
  return (
    <div>
      <ImagenFondo src={fondo} className="App-logo" alt="logo" />
      <div className="animate__animated animate__fadeInRight">
        <H1cargarCredito className="text-center">
          Proximamente
          <h1 className="text-center text-uppercase font-weight-bold ">
            Carregar Credito
          </h1>
        </H1cargarCredito>
      </div>
    </div>
  );
};

export default CarregarCredito;

const H1cargarCredito = styled.h1`
  margin-top: 40vh;
  font-weight: bolder;
`;
