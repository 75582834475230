import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import ModalTicketAposta from "./modalTicketAposta";

const TraerCuponesPendientes = ({ setViewCup, viewCup }) => {
  const [listaPremios, setListaPremios] = useState([]);
  const [viewCupDet, setViewCupDet] = useState(false);
  const [idApuesta, setIdApuesta] = useState();

  const procesarClick = (identificacion) => {
    setViewCupDet(true);
    setIdApuesta(listaPremios[identificacion]);
    console.log(idApuesta);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_VER_LISTADO_CUPONES + "/1000")
      .then((res) => setListaPremios(res.data));
    console.log(listaPremios);
  }, []);

  return (
    viewCup && (
      <>
        <DivGralCard>
          <DivCard className="card text-center animate__animated animate__fadeInDownBig">
            <div className="card-header">Cupones Pendientes</div>
            <div
              className="card-body"
              style={{ maxHeight: "17rem", overflow: "scroll" }}
            >
              <div
                className="card-text"
                style={{ fontSize: "3vw", textAlign: "-webkit-auto" }}
              >
                {listaPremios.reverse().map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-6" style={{ alignSelf: "center" }}>
                      {listaPremios[index].fechaApuesta.substring(8, 10) +
                        "/" +
                        listaPremios[index].fechaApuesta.substring(5, 7) +
                        "/" +
                        listaPremios[index].fechaApuesta.substring(0, 4) +
                        " " +
                        listaPremios[index].fechaApuesta.substring(11, 19)}
                      <br />
                      {listaPremios[index].cantLineas}{" "}
                      {listaPremios.cantLineas > 1 ? "Apuestas" : "Apuesta"}
                    </div>
                    <div className="col-3" style={{ alignSelf: "center" }}>
                      {" R$ " + listaPremios[index].importe}
                    </div>
                    <div className="col-2">
                      <button
                        className="btn btn-info"
                        id={index}
                        onClick={(e) => procesarClick(e.target.id)}
                      >
                        VER
                      </button>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
            <div className="card-footer">
              <ButtonOk
                onClick={() => setViewCup(false)}
                className="btn btn-primary"
              >
                OK
              </ButtonOk>
            </div>
          </DivCard>
        </DivGralCard>
        <ModalTicketAposta
          viewModal={viewCupDet}
          setviewModal={setViewCupDet}
          cuponActual={idApuesta}
        />
      </>
    )
  );
};

export default TraerCuponesPendientes;

const DivCard = styled.div`
  position: fixed;
  left: 5%;
  min-width: 20em;
  min-height: 13em;
  top: 18vh;
`;
const ButtonOk = styled.button`
  margin-top: 1em;
  min-width: 7em;
`;
const DivGralCard = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0000005e;
  position: fixed;
  top: 0;
  left: 0;
`;
