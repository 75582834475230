import React, { useEffect } from "react";
import styled from "styled-components";
import logoJDB from "../img/svg/logoJDB.svg";
import ok from "../img/svg/juego1/ok.svg";
import {
  BtnOk,
  DivCard,
  DivGralCard,
  DivImgLogo,
  Hr,
  ImagenOk,
} from "./modalMonto";

const ModalError = ({ mensaje, viewModalError, setviewModalError }) => {
  return (
    viewModalError && (
      <DivGralCard>
        <DivCard className="card animate__animated animate__fadeInDownBig">
          <div className="card-header"></div>
          <div className="card-body">
            <div className="container">
              <DivImgLogo className="imgLogo" src={logoJDB} alt="logoJDB" />

              <Hr />

              <div className="Row">
                <div className="text-center">
                  <h5>{mensaje}</h5>
                </div>
              </div>
              <Hr />
            </div>
            {/* 
            <ButtonOk
              onClick={() => setviewModal(false)}
              className="btn btn-primary"
            >
              ok
            </ButtonOk> */}
          </div>
          <div className="row">
            <BtnOk
              className="col-4"
              onClick={() => {
                setviewModalError(false);
              }}
            >
              <ImagenOk src={ok} alt="ok" />
            </BtnOk>
          </div>
        </DivCard>
      </DivGralCard>
    )
  );
};

export default ModalError;
