import React from "react";
import styled from "styled-components";
import botonOK from "../../../img/svg/juego1/icono_bot_ok.svg";
import botonEli from "../../../img/svg/juego1/icono_bot_eli.svg";
import botonSuerte from "../../../img/svg/juego1/suerte.png";
import fondoBoton from "../../../img/svg/juego1/boton_blanco.svg";

const DigitarNumero = ({ clickNumero, clickOk, clickBorrar }) => {
  return (
    <ItemAcordion>
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "17vh",
          zIndex: "0",
        }}
      >
        <DivDigitos style={{ borderRadius: "1em" }}>
          <div className="container">
            <div className="row">
              <DivBotonJuego
                className="col-sm suerte"
                id="suerte"
              ></DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                id="1"
                onClick={(e) => clickNumero(e.target.id)}
              >
                1
              </DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                id="2"
                onClick={(e) => clickNumero(e.target.id)}
              >
                2
              </DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                id="3"
                onClick={(e) => clickNumero(e.target.id)}
              >
                3
              </DivBotonJuego>
            </div>
            <div className="row">
              <DivBotonJuego className="col-sm">
                <div
                  className="row"
                  id="AO"
                  onClick={(e) => clickNumero(e.target.id)}
                >
                  <div
                    className="col-12"
                    style={{ fontSize: "1.5rem", paddingLeft: "2px" }}
                  >
                    {" "}
                    1/5
                  </div>
                  <div
                    className="col-12"
                    style={{
                      fontSize: "0.7rem",
                      marginTop: "-4px",
                      paddingLeft: "0.8rem",
                    }}
                  >
                    AO
                  </div>
                </div>
              </DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                id="4"
                onClick={(e) => clickNumero(e.target.id)}
              >
                4
              </DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                id="5"
                onClick={(e) => clickNumero(e.target.id)}
              >
                5
              </DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                id="6"
                onClick={(e) => clickNumero(e.target.id)}
              >
                6
              </DivBotonJuego>
            </div>
            <div className="row">
              <DivBotonJuego className="col-sm img-ok">
                <ImgEli
                  src={botonEli}
                  id="botonEli"
                  alt="botonEli"
                  onClick={clickBorrar}
                />
              </DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                id="7"
                onClick={(e) => clickNumero(e.target.id)}
              >
                7
              </DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                id="8"
                onClick={(e) => clickNumero(e.target.id)}
              >
                8
              </DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                id="9"
                onClick={(e) => clickNumero(e.target.id)}
              >
                9
              </DivBotonJuego>
            </div>
            <div className="row">
              <DivBotonJuego className="col-sm img-ok">
                <ImgEli
                  src={botonOK}
                  id="botonOK"
                  alt="imagenOK"
                  onClick={clickOk}
                />
              </DivBotonJuego>
              <DivBotonJuego
                className="col-sm"
                style={{ visibility: "hidden" }}
              ></DivBotonJuego>
              <DivBotonJuego
                className="col"
                id="0"
                onClick={(e) => clickNumero(e.target.id)}
              >
                0
              </DivBotonJuego>
              <div className="col form-check form-switch">
                {/* <Switch
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                /> */}
              </div>
            </div>
            <div className="row">
              <div className="col-8"></div>
              <div className="col-4">
                {/* <span style={{ marginLeft: "6px", fontWeight: "bold" }}>
                  T. a Pagar
                </span> */}
              </div>
            </div>
          </div>
        </DivDigitos>
      </div>
      <h2 className="accordion-header" id="cabecera"></h2>
    </ItemAcordion>
  );
};

export default DigitarNumero;

const Acordion = styled.div`
  position: fixed;
  bottom: 4rem;
  left: 0em;
  width: 22em;
`;

const Boton = styled.img`
  height: 100px;
  width: 144px;
  margin-top: 3vh;
  margin-left: 3vh;
`;

const DivBotonJuego = styled.div`
  background: url(${fondoBoton}) no-repeat;
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
  padding-top: 11px;
  padding-left: 18px;
  font-weight: bolder;
  margin-left: 0.4em;
  &.img-ok {
    padding-left: 5px !important;
  }
  &.suerte {
    background: url(${botonSuerte}) no-repeat center;
    background-size: contain;
  }
`;

const ImgEli = styled.img`
  vertical-align: middle;
  margin-top: -8px;
`;

const ItemAcordion = styled.div`
  background-color: transparent;
  border-color: transparent;
`;

const DivDigitos = styled.div`
  background-color: transparent;
`;
