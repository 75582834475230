import React, { useEffect } from "react";
import styled from "styled-components";
import logoJDB from "../img/svg/logoJDB.svg";
import ok from "../img/svg/juego1/ok.svg";

const ModalMonto = ({
  viewModalMonto,
  setviewModalMonto,
  cuponGenerado,
  setValorTotal,
  valorTotal,
  procesarApuesta,
}) => {
  useEffect(() => {
    console.log(cuponGenerado);
  }, [valorTotal]);

  return (
    viewModalMonto && (
      <DivGralCard>
        <DivCard className="card animate__animated animate__fadeInDownBig">
          <div className="card-header"></div>
          <div className="card-body">
            <div className="container">
              <DivImgLogo className="imgLogo" src={logoJDB} alt="logoJDB" />

              <div className="text-center">
                <h5>Digite el total a apostar</h5>
              </div>
              <Hr />

              <div className="Row">
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      R$
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    value={valorTotal}
                    onChange={(e) => setValorTotal(e.target.value)}
                  />
                </div>
              </div>
              <Hr />
            </div>
            {/* 
            <ButtonOk
              onClick={() => setviewModal(false)}
              className="btn btn-primary"
            >
              ok
            </ButtonOk> */}
          </div>
          <div className="row">
            <BtnOk
              className="col-4"
              onClick={() => {
                setviewModalMonto(false);
              }}
            >
              <ImagenOk src={ok} alt="ok" />
            </BtnOk>
          </div>
        </DivCard>
      </DivGralCard>
    )
  );
};

export default ModalMonto;

export const DivCard = styled.div`
  top: 28%;
  left: 5%;
  width: 20em;
  height: 19em;
`;
export const DivGralCard = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #0000005e;
  position: fixed;
  top: 0;
  left: 0;
`;

export const DivImgLogo = styled.img`
  height: 4rem;
  margin-left: 5em;
`;

export const BtnOk = styled.button`
  height: 4.5rem;
  width: 4.5rem;
  background-color: #33cc33;
  border-radius: 50%;
  position: absolute;
  z-index: 3 !important;
  bottom: 1.5vh;
  right: 3vh;
  box-shadow: 1px 2px 5px 2px black;
`;

export const Hr = styled.hr`
  border: 0;
  background-color: #fff;
  border-top: 3px dotted black;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

export const ImagenOk = styled.img`
  width: 5em;
  position: fixed;
  bottom: 0.1em;
  right: 1.1em;
`;
