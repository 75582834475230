import React, { useState } from "react";
import { DivSalir, LogoNombreElemento } from "./navbar";
import premio from "../img/svg/premios.svg";
import movil from "../img/svg/c_moviles.svg";
import notificaciones from "../img/svg/notificaciones.svg";
import p_servicios from "../img/svg/p_servicios.svg";
import mi_cuenta from "../img/svg/mi_cuenta.svg";
import inicio from "../img/svg/inicio.svg";
import salir from "../img/svg/salir.svg";
import totales from "../img/svg/totales.svg";
import sangria from "../img/svg/sangria.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleLogout } from "../redux/slices/userSlice";
import styled from "styled-components";
import { FiHelpCircle } from "react-icons/fi";
import ModalNotificaciones from "./modalNotificaciones";
import $ from "jquery";

const NavPlataforma = ({ viewNotif, setviewNotif }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
      className="offcanvas-body"
      style={{ marginLeft: "-2vh", marginTop: "4vh" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              navigate("/");
            }}
            className="col-12"
          >
            <LogoNombreElemento
              icono={<img src={inicio} alt="notificaciones" />}
              nombre={"Inicio"}
            />
          </div>
          <div
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => setviewNotif(true)}
            className="col-12"
          >
            <LogoNombreElemento
              icono={<img src={notificaciones} alt="notificaciones" />}
              nombre={"Notificações"}
            />
          </div>
          
          <div
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              navigate("/sangria");
            }}
            className="col-12"
          >
            <LogoNombreElemento
              icono={<img src={sangria} alt="sangria" />}
              nombre={"Sangria"}
            />
          </div>
          <div
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              navigate("/premios");
            }}
            className="col-12"
          >
            <LogoNombreElemento
              icono={<img src={premio} alt="premio" />}
              nombre={"Prêmios"}
            />
          </div>
          <div
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              navigate("/totais");
            }}
            className="col-12"
          >
            <LogoNombreElemento
              icono={<img src={totales} alt="totales" />}
              nombre={"Totais"}
            />
          </div>
          <div
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              navigate("/minhaconta");
            }}
            className="col-12"
          >
            <LogoNombreElemento
              icono={<img src={mi_cuenta} alt="mi cuenta" />}
              nombre={"Minha Conta"}
            />
          </div>

          <DivSalir
            onClick={() => {
              dispatch(handleLogout());
              navigate("/login");
            }}
            className="col-12"
          >
            <LogoNombreElemento
              icono={<img src={salir} alt="salir" />}
              nombre={"Sair"}
            />
          </DivSalir>
        </div>
      </div>
    </div>
  );
};

export default NavPlataforma;
