import { createSlice } from "@reduxjs/toolkit";
import { fetchingIN, fetchingOUT } from "./fetchingSlice";

export const eventoSlice = createSlice({
  name: "eventos",
  initialState: {
    eventosList: [],
  },
  reducers: {
    agregarEvento: (state, action) => {
      state.eventosList = action.payload;
    },
    borrarEvento: (state, action) => {
      state.eventosList = action.payload;
    },
  },
});

export const { agregarEvento, borrarEvento } = eventoSlice.actions;

export default eventoSlice.reducer;

//----------ACTIONS-------------------

export const agregoEvento = (eventos) => (dispatch) => {
  setTimeout(() => {
    dispatch(agregarEvento(eventos));
  }, 1000);
};

export const quitarEvento = (eventos) => (dispatch) => {
  setTimeout(() => {
    dispatch(borrarEvento(eventos));
  }, 1000);
};
