import { createSlice } from "@reduxjs/toolkit";
import { fetchingIN, fetchingOUT } from "./fetchingSlice";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    location: "/",
  },
  reducers: {
    goToPage: (state, action) => {
      state.location = action.payload;
    },
  },
});

export const { goToPage } = locationSlice.actions;

export default locationSlice.reducer;

//----------ACTIONS-------------------

export const goTo = (pathname) => (dispatch) => {
  dispatch(fetchingIN());
  setTimeout(() => {
    dispatch(goToPage(pathname));
    dispatch(fetchingOUT());
  }, 300);
};
