import React, { forwardRef } from "react";
import logoJDB from "../../../img/svg/juego1/logoJDB.svg";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DivSpanTextVerde } from "./eventos";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { goTo } from "../../../redux/slices/location";
import SinEventos from "./sinEventos";
import { updateUser } from "../../../redux/slices/userSlice";
import { agregarEvento, borrarEvento } from "../../../redux/slices/evento";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { handleinitParametros } from "../../../redux/slices/parametros";
import { parametros } from "../../../components/parametros";

const Juego = () => {
  let dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [imagen, setimagen] = useState(undefined);
  const [parameters, setParameters] = useState({});
  const [codigoJuego, setCodigoJuego] = useState(undefined);
  const [codigoOrganizacion, setcodigoOrganizacion] = useState(undefined);
  const [eventosDisponibles, setEventosDisponibles] = useState([]);
  const [eventosActivos, setEventosActivos] = useState([]);
  const [saldo, setSaldo] = useState(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [alcance, setAlcance] = useState(undefined);

  const handleClick = (evento) => {
    if (eventosActivos.includes(evento)) {
      let filterList = eventosActivos.filter(
        (eventoactual) => evento.id !== eventoactual.id
      );
      setEventosActivos(filterList);
      dispatch(borrarEvento(filterList));
    } else {
      setEventosActivos([...eventosActivos, evento]);
      dispatch(agregarEvento([...eventosActivos, evento]));
    }
  };

  const isActivebutton = (id) => {
    return eventosActivos.includes(id);
  };

  const EventosJuego = () => {
    let componenteEventos = eventosDisponibles?.map((evento) => (
      <div className="col-6">
        <br />

        <ButtonPlay
          type="button"
          className="btn btn-secondary dropdown-toggle"
          style={{
            backgroundColor: isActivebutton(evento) ? "#ffffcc" : "",
            color: isActivebutton(evento) ? "#0099cc" : "",
          }}
          onClick={() => handleClick(evento)}
        >
          {evento.leyenda} {evento.listaSorteos[0].horario}Hs.
        </ButtonPlay>
      </div>
    ));

    return (
      <div className="container">
        <div className="row">{componenteEventos}</div>
      </div>
    );
  };

  useEffect(() => {
    import(`../../../img/svg/juego1/fondo_juego.png`).then((image) =>
      setimagen(image.default)
    );
  }, []);

  useEffect(() => {
    initParametros();
  }, []);

  const initParametros = () => {
    axios
      .get(process.env.REACT_APP_GET_PARAMETROS_INICIALES)
      .then((response) => {
        setParameters(response.data);
        setCodigoJuego(response.data.codigoJuego);
        setcodigoOrganizacion(response.data.codigoOrganizacion);
        setEventosDisponibles(response.data.listaEventos);
        setSaldo(response.data.saldo);
        dispatch(updateUser(response.data.saldo));
      })
      .catch((err) => {
        console.error(err);
        setParameters(parametros);
        setCodigoJuego(0);
        setcodigoOrganizacion(0);
        setEventosDisponibles(parametros.eventosDisponibles[0].listaEventos);
        setSaldo(550);
        dispatch(updateUser(550));
      });
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-primary" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <DivContainer>
      <ImagenFondo src={imagen} alt="fondo juego" />

      {eventosDisponibles?.length === 0 ? (
        <SinEventos />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-6"></div>
                  <div className="col-6 ">
                    <img src={logoJDB} alt="logoJDB" />
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="container">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      customInput={<ExampleCustomInput />}
                    />
                  </div>
                </div>
              </div>
              <EventosJuego />

              <div className="container mt-4">
                <div className="row">
                  <div className="col-6"></div>
                  <div className="col-6">
                    <ButtonOk
                      disabled={eventosActivos.length === 0}
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      onClick={() => {
                        dispatch(handleinitParametros(parameters));
                        dispatch(goTo("/games/jogo-do-bicho/eventos"));
                        navigate("/games/jogo-do-bicho/eventos");
                      }}
                    >
                      OK
                    </ButtonOk>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </DivContainer>
  );
};

export default Juego;

const DivContainer = styled.div`
  background-image: url(${(props) => props.fondo});
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
`;

const FormSelect = styled.div`
  width: 50%;
  box-shadow: 0px 6px #0099cc;
  border-color: #0099cc;
  border-radius: 1em;
  margin-top: 17px;
`;

const ButtonPlay = styled.button`
  background-color: #0099cc;
  border-radius: 10px;
  color: white;
  height: 2.5rem;
  font-size: 1rem;
  white-space: nowrap;
`;

const ImagenFondo = styled.img`
  position: fixed;
  z-index: -1;
  width: 23em;
  left: 0em;
  top: 0em;
  height: 100vh;
`;

const ButtonOk = styled.button`
  background-color: #33cc33;
  border-radius: 10px;
  color: white;
  width: 10rem;
  height: 3.5rem;
  font-size: 1.5rem;
`;
